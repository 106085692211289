import Snowflakes from "@/components/site/parts/Snowflakes";
import classNames from "classnames";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

const HolidayGreetingsPageComponent = () => {
  return (
    <>
      <Snowflakes />
      <div className={classNames("container relative pb-8 lg:pb-16")}>
        <ReactPlayer
          className="flex !w-full items-center justify-center lg:!h-[710px]"
          url="https://vimeo.com/1039654988"
          background="transparent"
          controls
          loop
          muted={true}
          playing={true}
          volume={0.3}
          width="100%"
          preload="auto"
          config={{
            youtube: {
              playerVars: {
                showinfo: 1,
                modestbranding: 1,
                rel: 0,
              },
            },
          }}
        />
      </div>
    </>
  );
};

export default HolidayGreetingsPageComponent;
