import SocialLinks from "@/components/site/parts/SocialLinks";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

import { ContactsUsForm } from "../../parts/ContactUsForm";
import ContactUsHeader from "../../parts/ContactUsHeader";
import { ContactInfoContent, MainSectionContent } from "./types";

const ContactUsPageComponent = () => {
  const { t } = useTranslation("common");
  const sections = [
    { key: "title", type: "MainSectionContent" },
    { key: "contactInfo", type: "ContactInfoContent" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`contactUs.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const { title, contactInfo } = sectionContents as {
    title: MainSectionContent;
    contactInfo: ContactInfoContent;
  };

  return (
    <div className="container mx-auto !max-w-[1118px] pb-8 md:pb-16">
      <div className="flex flex-col justify-center gap-6 md:flex-row">
        <div className="mb-0 w-full md:mb-4 lg:min-w-[407px] xl:min-w-[507px]">
          <h1 className="mb-0 md:mb-4">
            <ContactUsHeader title={title} />
          </h1>
          <p className="mb-0 text-site-text-18-26 font-light md:mb-6 md:text-site-text-20-26">
            {parse(title.description)}
          </p>
          <div className="hidden flex-col self-start md:flex">
            <div className="mb-2 flex flex-row items-center text-site-text-16-24 font-light">
              <Link
                className="relative flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover"
                href={`tel:1-800-644-2478`}>
                <i className="icon-phone_f mr-3"></i>
                {contactInfo.phone}
              </Link>
            </div>
            <div className="mb-2 flex flex-row items-center text-site-text-16-24 font-light">
              {contactInfo.address_1.mapLink ? (
                <Link
                  href={contactInfo.address_1.mapLink}
                  target="_blank"
                  className="group flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover">
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:!text-site-btn-orange-hover"></i>
                  {contactInfo.address_1.address}
                </Link>
              ) : (
                <>
                  <i className="icon-location_f mr-3 w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>
                  <span>{contactInfo.address_1.address}</span>
                </>
              )}
            </div>
            <div className="mb-4 flex flex-row items-center text-site-text-16-24 font-light">
              {contactInfo.address_2.mapLink ? (
                <Link
                  href={contactInfo.address_2.mapLink}
                  target="_blank"
                  className="group flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover">
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>
                  {contactInfo.address_2.address}
                </Link>
              ) : (
                <>
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>

                  <span>{contactInfo.address_2.address}</span>
                </>
              )}
            </div>
            <div className="text-site-text-18-27 font-light">
              <div className="mb-4">{contactInfo.socialMedia.title}</div>
              <SocialLinks />
            </div>
          </div>
        </div>
        <div className="relative w-full lg:min-w-[670px]">
          <ContactsUsForm classWrapper="shadow-contact-form" fieldsSettings={{ textAria: { rows: 6 } }} />
          <div className="mt-9.5 flex flex-col self-start md:hidden">
            <div className="mb-2 flex flex-row items-center text-site-text-16-24 font-light">
              <Link
                className="relative flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover"
                href={`tel:1-800-644-2478`}>
                <i className="icon-phone_f mr-3"></i>
                {contactInfo.phone}
              </Link>
            </div>
            <div className="mb-2 flex flex-row items-center text-site-text-16-24 font-light">
              {contactInfo.address_1.mapLink ? (
                <Link
                  href={contactInfo.address_1.mapLink}
                  target="_blank"
                  className="group flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover">
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:!text-site-btn-orange-hover"></i>
                  {contactInfo.address_1.address}
                </Link>
              ) : (
                <>
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>
                  <span>{contactInfo.address_1.address}</span>
                </>
              )}
            </div>
            <div className="mb-4 flex flex-row items-center text-site-text-16-24 font-light">
              {contactInfo.address_2.mapLink ? (
                <Link
                  href={contactInfo.address_2.mapLink}
                  target="_blank"
                  className="group flex flex-row items-center transition-all duration-300 hover:text-site-btn-orange-hover">
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>
                  {contactInfo.address_2.address}
                </Link>
              ) : (
                <>
                  <i className="icon-location_f w-6 transition-all duration-300 group-hover:text-site-btn-orange-hover"></i>

                  <span>{contactInfo.address_2.address}</span>
                </>
              )}
            </div>
            <div className="text-site-text-18-27 font-light">
              <div className="mb-4">{contactInfo.socialMedia.title}</div>
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPageComponent;
