import classNames from "classnames";
import parse from "html-react-parser";
import Image from "next/image";
import React from "react";

import { Button } from "../Button";
import { AccordionItemProps } from "./types";

export const AccordionItem = ({
  title,
  content,
  isActive,
  iconType,
  buttonLink,
  buttonText,
  onToggle,
  classWrapper,
  classButton,
  classContentWrapper,
}: AccordionItemProps) => {
  // const Icon = { plus: FiPlus, chevron: IoChevronDown }[iconType || "chevron"];

  return (
    <div
      className={classNames(
        "rounded-[10px] border-[1px]",
        classWrapper,
        isActive ? "border-site-background-blue" : "border-transparent"
      )}>
      <button
        className={classNames(
          "flex-rox flex w-full justify-between focus:outline-none focus:ring-0 active:outline-none active:ring-0",
          classButton
        )}
        onClick={onToggle}>
        <h3 className={classNames("text-left text-site-text-20-26 font-normal md:font-medium")}>{title}</h3>
        <i
          className={classNames(
            "icon-arrowDown text-[12px] transition-all duration-300",
            isActive ? "rotate-180 transform" : "rotate-0 transform"
          )}></i>
      </button>
      <div
        className={classNames(
          "-mt-1.5 grid min-h-0 overflow-hidden transition-all duration-300",
          isActive ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        )}>
        <div className="min-h-0">
          <div
            className={classNames(
              "p-6 pl-16 text-site-text-16-24 font-light md:text-site-text-18-27",
              classContentWrapper
            )}>
            {parse(content)}
            <Button
              size="medium"
              color="orange"
              textSize="small"
              rounded="medium"
              font="light"
              classButton="  leading-none !text-white w-fit"
              href={buttonLink}>
              <span className="mr-1">{buttonText}</span>
              <i className="icon-arrowR relative right-0 text-[18px] transition-all duration-300 group-hover:-right-1 group-hover:text-[20px]"></i>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
