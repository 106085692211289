import classNames from "classnames";
import Image from "next/image";
import React from "react";

import { MainSectionProps } from "./types";

export const IconTextCardWide = ({ classText, classIcon, classWrapper, card }: MainSectionProps) => {
  return (
    <div className={classNames("flex rounded-[24px]", classWrapper)}>
      <div
        className={classNames(
          "flex flex-[0_0_auto] items-center justify-center self-center rounded-full",
          classIcon
        )}>
        {typeof card.icon === "string" ? (
          <Image
            src={`/images/icon/${card.icon}`}
            className={classNames("h-full w-auto")}
            loading="lazy"
            height={42}
            width={42}
            alt={card.title}
          />
        ) : (
          card.icon
        )}
      </div>
      <div className={classNames("flex flex-col justify-center gap-2")}>
        <h5 className={classNames("text-site-text-18-21 font-normal md:text-site-text-24-33", classText)}>
          {card.title}
        </h5>
      </div>
    </div>
  );
};
