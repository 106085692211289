import styled from "styled-components";

export const PageContainer = styled.div`
  color: #11374d;
  width: 100%;
  font-family: Avenir;
  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    // margin-bottom: 16px;
    // margin-bottom: -8px;
  }
  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 27px;
    // margin-bottom: -8px;
  }
  a {
    color: #0097ce;
  }
  :is(ol, ul) {
    padding-left: 20px;
    font-size: 18px;
    line-height: 27px;
    // margin-bottom: -8px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  ol {
    list-style-type: lower-alpha !important;
  }
  ul {
    list-style-type: auto !important;
  }
  ol {
    ul {
      list-style-type: disc !important;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black;
    margin-bottom: 16px;
    thead {
      font-size: 16px;
      font-weight: 500;
      line-height: 23.2px;
    }
    tbody {
      font-size: 18px;
      font-weight: 350;
      line-height: 27px;
    }
    th,
    td {
      border: 1px solid black;
      padding: 8px;
    }
    tr td:last-child {
      word-break: break-word;
    }
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 24px;
      line-height: 33px;
    }
    p {
      font-size: 18px;
      line-height: 27px;
      //   margin-bottom: 32px;
    }
  }
`;
