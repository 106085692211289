import { MainSectionContent, FormsContent } from "@/components/site/pages/BehavioralHealthForms/types";
import { MainSection } from "@/components/site/parts/MainSection";
import ProviderForms from "@/components/site/parts/ProviderForms";
import { PageActive } from "@/types/entities/page";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import React from "react";

interface DynamicPageProps {
  pageData: PageActive;
}
const BehavioralHealthFormsPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");

  const sections = [
    { key: "mainSection", type: "" },
    { key: "forms", type: "" },
  ];

  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`BehavioralHealthForms.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const { mainSection, forms } = sectionContents as {
    mainSection: MainSectionContent;
    forms: FormsContent[];
  };
  const files: { id?: number; name: string | null; file: string }[] = pageData.files.length
    ? pageData.files
    : forms;
  return (
    <>
      <ProviderForms nameSection="BehavioralHealthForms" forms={files}>
        <div className="main relative order-1 -mt-[106px] bg-site-background-gray pt-[115px] md:order-2 md:-mt-[220px] md:pt-[200px]">
          <MainSection classChildrenWrapper="max-w-[930px] mx-auto" classImage="" position="right">
            <div className="md:pl-4">
              <h1 className="mb-6 text-center md:mb-9.5">
                <span className="text-site-text-32-36 relative mr-2 mt-1 inline-flex w-fit font-normal capitalize md:text-site-text-52-62">
                  {mainSection.title.line}
                </span>
                <span className="text-site-text-32-36 relative inline-flex w-fit font-normal capitalize before:absolute before:bottom-[-3px] before:right-[0px] before:z-1 before:h-[9px] before:w-full before:bg-[url('/images/site/AccessSimpleMSKForms/decor-line.svg')] before:bg-cover before:bg-no-repeat after:absolute after:right-[-23px] after:top-0 after:h-[17px] after:w-[28px] after:bg-[url('/images/site/AccessSimpleMSKForms/decor.svg')] after:bg-contain after:bg-no-repeat md:text-site-text-52-62 md:before:bottom-[-10px] before:md:h-[14px] md:after:right-[-26px] after:md:h-[32px]">
                  {mainSection.title["lineDecoration-2"]}
                </span>
              </h1>
            </div>
          </MainSection>
        </div>
      </ProviderForms>
    </>
  );
};

export default BehavioralHealthFormsPageComponent;
