import { AccordionContainer } from "@/components/site/parts/AccordionVacancies/styles";
import React from "react";

import { AccordionVacancies } from "../../parts/AccordionVacancies";
import { ComponentProps } from "../Careers/types";

const VacanciesPageComponent = ({ allVacancies }: ComponentProps) => {
  return (
    <div className="container pb-11">
      <AccordionContainer>
        <AccordionVacancies
          items={allVacancies.map((item) => ({
            title: item.title,
            content: item.contentHtml,
            buttonText: item.buttonText,
            buttonLink: item.buttonLink,
          }))}
          iconType="plus"
          itemOpen={0}
          classButton="py-8 px-8.5 rounded-[8px] "
          classContainer="flex flex-col gap-6 pt-5 "
          classWrapper="overflow-hidden shadow-icon-card"
        />
      </AccordionContainer>
    </div>
  );
};

export default VacanciesPageComponent;
