import classNames from "classnames";
import parse from "html-react-parser";
import Image from "next/image";
import React from "react";

import { AccordionItemProps } from "./types";

export const AccordionItem = ({
  title,
  iconTitle,
  content,
  isActive,
  iconType,
  onToggle,
  classWrapper,
  classButton,
  classContentWrapper,
}: AccordionItemProps) => {
  return (
    <div
      className={classNames(
        "overflow-hidden rounded-[10px] border-[1px] transition-all duration-300 hover:border-site-background-blue",
        classWrapper,
        isActive ? "border-site-background-blue" : "border-transparent"
      )}>
      <button
        className={classNames(
          "flex-rox flex w-full justify-between bg-white px-5 py-4 focus:outline-none focus:ring-0 active:outline-none active:ring-0",
          classButton
        )}
        onClick={onToggle}>
        <h3
          className={classNames(
            "flex items-center gap-4 text-left text-site-text-18-27 font-normal capitalize md:font-medium"
          )}>
          {iconTitle && (
            <Image
              src={`/images/site/SimpleMSK/${iconTitle}`}
              alt="icon"
              width={26}
              height={26}
              className="h-6.5 w-auto shrink-0 object-cover"
            />
          )}
          {title}
        </h3>
        <i
          className={classNames(
            "icon-arrowDown self-center text-[18px] transition-all duration-300",
            isActive ? "rotate-180" : ""
          )}></i>
      </button>
      <div
        className={classNames(
          "grid min-h-0 overflow-hidden bg-white transition-all duration-300",
          isActive ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        )}>
        <div className="min-h-0">
          <div
            className={classNames(
              "pb-6 pl-8 pr-6 text-site-text-16-24 font-light md:pl-16 md:text-site-text-18-26",
              classContentWrapper
            )}>
            {parse(content)}
          </div>
        </div>
      </div>
    </div>
  );
};
