import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import IconArrow from "@/components/site/parts/IconArrow";
import { MainSection } from "@/components/site/parts/MainSection";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Accordion } from "../../parts/Accordion";
import { AccordionIcon } from "../../parts/AccordionIcon";
import { AccordionVertical } from "../../parts/AccordionVertical";
import { Button } from "../../parts/Button";
import { CollToAction } from "../../parts/CollToAction";
import {
  ContainerDeacription,
  ContainerSlider,
  SliderPaginationDark,
  SliderPaginationButtons,
  SliderContainerRealPeople,
} from "./styles";
import {
  MainSectionProps,
  AccessibleSectionContent,
  ActivitySectionContent,
  CertifiedSectionContent,
  ValidatedOutcomesSectionContent,
  ComprehensiveCareSectionContent,
  VirtualMSKCareSectionContent,
  RedefiningSectionContent,
  SwissReFoundationSectionContent,
  RealResultsSectionContent,
  ImageDescriptionSectionContent,
  RealPeopleSectionContent,
} from "./types";

interface DynamicPageProps {
  pageData: PageActive;
}

const SimpleMSKPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");
  const programs = useRef<HTMLDivElement>(null);
  const { setOpenModal, setClosingModal } = useSiteContext();
  const [swiperSlider, setSwiperSlider] = useState<any | null>(null);
  const [sliderCareSection, setSliderCareSection] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const sections = [
    { key: "mainSection", type: "MainSectionProps" },
    { key: "accessibleSection", type: "AccessibleSectionContent" },
    { key: "activitySection", type: "ActivitySectionContent" },
    { key: "certifiedSection", type: "CertifiedSectionContent" },
    { key: "validatedOutcomesSection", type: "ValidatedOutcomesSectionContent" },
    { key: "comprehensiveCareSection", type: "ComprehensiveCareSectionContent" },
    { key: "virtualMSKCareSection", type: "VirtualMSKCareSectionContent" },
    { key: "rersonCareSection", type: "VirtualMSKCareSectionContent" },
    { key: "redefiningSection", type: "RedefiningSectionContent" },
    { key: "swissReFoundationSection", type: "SwissReFoundationSectionContent" },
    { key: "realResultsSection", type: "RealResultsSectionContent" },
    { key: "collToActionSection", type: "CollToActionContent" },
    { key: "personCareSection", type: "VirtualMSKCareSectionContent" },
    { key: "aptaSection", type: "ImageDescriptionSectionContent" },
    { key: "realPeopleSection", type: "RealPeopleSectionContent" },
  ];

  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`SimpleMSK.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    accessibleSection,
    activitySection,
    certifiedSection,
    validatedOutcomesSection,
    comprehensiveCareSection,
    virtualMSKCareSection,
    personCareSection,
    redefiningSection,
    swissReFoundationSection,
    realResultsSection,
    aptaSection,
    collToActionSection,
    realPeopleSection,
  } = sectionContents as {
    mainSection: MainSectionProps;
    accessibleSection: AccessibleSectionContent;
    activitySection: ActivitySectionContent;
    certifiedSection: CertifiedSectionContent;
    validatedOutcomesSection: ValidatedOutcomesSectionContent;
    comprehensiveCareSection: ComprehensiveCareSectionContent;
    virtualMSKCareSection: VirtualMSKCareSectionContent;
    personCareSection: VirtualMSKCareSectionContent;
    redefiningSection: RedefiningSectionContent;
    swissReFoundationSection: SwissReFoundationSectionContent;
    realResultsSection: RealResultsSectionContent;
    aptaSection: ImageDescriptionSectionContent;
    collToActionSection: CollToActionContent;
    realPeopleSection: RealPeopleSectionContent;
  };

  const sourcesMapResult = realResultsSection?.sources
    ?.map(
      (source, indexSours) =>
        `<p key=real-sources${indexSours} class="text-site-text-8-12 flex flex-row">
      <span class="mr-1">${indexSours + 1}.</span>
      <span>${source}</span>
    </p>`
    )
    .join("");

  const goToSlide = (tabs: number, slideIndex: number) => {
    swiperSlider && swiperSlider.slideTo(slideIndex);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setIsMobile(entries[0].contentRect.width < 1024);
    });
    if (programs.current) {
      resizeObserver.observe(programs.current);
    }

    return () => resizeObserver.disconnect();
  }, [programs]);

  const programsTabsPosition = (slideIdx: number) => {
    const tabs = comprehensiveCareSection.sliderProgram[slideIdx].tabs.length;
    return tabs > 1 ? "-left-[calc(22%)]" : "left-0";
  };
  return (
    <>
      <MainSection
        imgUrl={`/images/site/SimpleMSK/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper="order-2"
        classImage="order-1 max-h-[260px] xs:max-h-[610px] md:min-h-[609px] md:max-w-[609px] object-top"
        position="right">
        <Image
          className="mb-2 h-full max-h-[33px] w-auto object-cover"
          src={`/images/site/SimpleMSK/logo_brands.svg`}
          alt="Simple Therapy"
          width="187"
          height="29"
        />
        <h1 className="mb-2.5 md:mb-10">
          <span className="relative mr-2 inline-flex text-site-text-32-38 font-medium capitalize text-site-text-blue-1 before:absolute before:bottom-[-18px] before:right-[0px] before:h-[24px] before:w-full before:bg-[url('/images/site/SimpleMSK/decor_line.svg')] before:bg-contain before:bg-no-repeat sm:text-site-text-38-38 md3:text-site-text-56-67 md3:before:bottom-[-10px]">
            {mainSection.title.lineDecoration}
          </span>
          <span className="relative inline text-site-text-32-38 font-medium capitalize text-site-text-blue-1 sm:text-site-text-38-38 md3:text-site-text-56-67">
            {mainSection.title.line}
          </span>
        </h1>
        <h2 className="mb-4 text-site-text-24-28 md:mb-10 md:text-site-text-32-35">{mainSection.subTitle}</h2>
        <div
          className={classNames(
            "rounded-[24px] bg-orange-10 p-6 text-site-text-16-24 font-light md:p-10 md:text-site-text-18-27"
          )}>
          {parse(mainSection?.description ?? "")}
        </div>
      </MainSection>
      {accessibleSection && (
        <div className="bg-orange-1 py-8 sm:py-10 md:py-16">
          <div className="container text-white">
            <h2 className="mb-6 max-w-[900px] text-site-text-28-33 md:text-site-text-48-57">
              {parse(accessibleSection.title)}
            </h2>
            <div className="text-site-text-16-24 font-light md:text-site-text-18-27">
              {parse(accessibleSection.description)}
            </div>
          </div>
        </div>
      )}
      <div className="bg-orange-10 py-8 sm:py-10 md:py-16">
        <div className="container">
          <h2 className="mx-auto mb-8 max-w-[328px] text-center text-site-text-28-33 text-orange-1 sm:max-w-none md:text-site-text-48-57">
            {activitySection?.title}
          </h2>
          <div className="grid grid-cols-1 gap-4 xs:grid-cols-2 sm:gap-8 md:grid-cols-3 md:gap-10 lg:gap-16">
            {/* ="grid grid-cols-1 gap-4 xs:grid-cols-2 sm:gap-8 md:grid-cols-3 md:gap-10 lg:gap-16" */}
            {activitySection?.cards.map((activity, indexActivity) => (
              <div key={`activity-${indexActivity}`} className="group">
                <Image
                  src={`/images/site/SimpleMSK/${activity.imageUrl}`}
                  alt={activity.title}
                  width="330"
                  height="267"
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  className="mx-auto h-[305px] max-h-[305px] min-h-[305px] w-full rounded-xl object-cover transition-all duration-300 group-hover:mb-2 group-hover:h-[230px] group-hover:min-h-[230px]"
                />
                <div className="rounded-2xl px-4 pb-4 pt-2 transition-all duration-300 group-hover:bg-orange-1 sm:group-hover:pb-10 md:group-hover:pb-20">
                  <h3 className="mb-1 text-site-text-20-24 font-medium capitalize text-orange-1 transition-all duration-300 group-hover:text-white md:text-site-text-24-33">
                    {parse(activity.title)}
                  </h3>
                  <p className="text-site-text-16-24 font-light text-site-background-blue transition-all duration-300 group-hover:text-white">
                    {parse(activity.description)}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white pb-8 pt-8 md:pb-6 md:pt-16">
        <div className="container !max-w-[1182px]">
          <h2 className="mb-6 flex flex-row text-site-text-28-33 md:block md:text-site-text-48-57">
            <span>{certifiedSection.title}</span>
            <Image
              src={`/images/site/SimpleMSK/${certifiedSection.imageUrl}`}
              alt="certified"
              width="80"
              height="80"
              className="inline-block shrink-0 object-cover md:hidden"
            />
          </h2>
          <div className="mb-4 flex flex-row gap-6 text-site-text-16-24 font-light md:mb-7 md:text-site-text-18-27">
            <p className="max-w-[1030px]">{certifiedSection.description}</p>
            <Image
              src={`/images/site/SimpleMSK/${certifiedSection.imageUrl}`}
              alt="certified"
              width="115"
              height="115"
              className="hidden shrink-0 object-cover md:inline-block"
            />
          </div>
          <div className="mb-6">
            <div className="mx-auto flex max-w-[1075px] flex-col gap-6 md:flex-row">
              <div className="flex flex-col items-center justify-center rounded-[24px] bg-orange-1 px-6 py-13 text-white md:flex-[1_0_304px]">
                <h3 className="mb-4 text-center text-site-text-20-26">
                  {certifiedSection.cards[0].description}
                </h3>

                <p className="text-center text-site-text-48-53 font-medium">
                  {certifiedSection.cards[0].title}
                </p>
              </div>
              <div className="flex flex-wrap justify-center gap-6">
                {certifiedSection.cards
                  .slice(1, certifiedSection.cards.length)
                  .map((card, indexCertified) => (
                    <div
                      key={`card-${indexCertified}`}
                      className="flex max-h-[172px] flex-[1_1_230px] flex-col items-center justify-center rounded-[24px] bg-orange-10 px-6 py-13 shadow-icon-card sm:max-w-[263px]">
                      <h3 className="mb-2 text-center text-site-text-32-38 font-medium">{card.title}</h3>
                      <p className="text-center text-site-text-16-24 font-light sm:text-site-text-18-27">
                        {card.description}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div>
            <h3 className="mb-2 text-site-text-12-18 font-bold capitalize">sources:</h3>
            <div className="flex flex-col gap-1">
              {certifiedSection.sources.map((source, indexSours) => (
                <p key={`certified-${indexSours}`} className="text-site-text-8-12">
                  <span className="mr-1">{indexSours + 1}.</span>
                  <span>{source}</span>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-orange-10 py-8 sm:py-10 md:py-16">
        <div className="container flex !max-w-[1166px] flex-col">
          <h2 className="mb-6 text-site-text-28-33 md:text-site-text-48-57">
            {validatedOutcomesSection.title}
          </h2>
          <p className="mb-6 text-site-text-18-27 font-light">{validatedOutcomesSection.description}</p>
          <div className="mb-6 flex flex-wrap justify-center gap-6">
            {validatedOutcomesSection.cards.map((card, indexValidate) => (
              <div
                key={`validated-${indexValidate}`}
                className="flex min-h-[335px] max-w-[400px] flex-[1_1_350px] flex-col items-center justify-between rounded-[24px] bg-white px-6 py-8">
                <Image
                  src={`/images/site/SimpleMSK/${card.imageUrl}`}
                  alt={"Card company"}
                  width="330"
                  height="77"
                  className="mb-4 max-h-[77px] rounded-2xl"
                />
                <div className="mb-6">{parse(card.description)}</div>

                <p className="flex flex-row items-center justify-center gap-2 text-center text-site-text-20-26 font-medium text-primary-1">
                  <Image
                    src={`/images/site/SimpleMSK/outcomex_card_icon.svg`}
                    alt="icon"
                    width="20"
                    height="20"
                    className="inline-block shrink-0"
                  />
                  {card.label}
                </p>
              </div>
            ))}
          </div>
          <p className="mb-6 text-site-text-18-27 font-light">{validatedOutcomesSection.descriptionBottom}</p>
          <Button
            size="large"
            color="darkOrange"
            textSize="medium"
            font="light"
            rounded="medium"
            href={validatedOutcomesSection.button.link || "#"}
            classButton=" w-fit gap-2 self-center">
            <span>{validatedOutcomesSection.button.text}</span>
            <span className="relative right-0 text-[20px] transition-all duration-300 group-hover:-right-1 group-hover:text-[24px]">
              <i className="icon-arrowR relative right-0 flex self-center text-[18px] font-[200] transition-all duration-300 group-hover:-right-1"></i>
            </span>
          </Button>
        </div>
      </div>
      <div ref={programs} className="bg-site-background-white py-8 sm:py-10 md:py-16">
        <div className="container">
          <h2 className="mb-4 text-site-text-28-33 font-medium md:mb-6 md:text-site-text-48-57">
            {comprehensiveCareSection.title}
          </h2>
          <p className="mb-4 text-site-text-16-24 font-light md:mb-8 md:text-site-text-18-27">
            {comprehensiveCareSection.description}
          </p>
          <div className="mb-4 rounded-[20px] bg-site-background-gray p-4 md:mb-16 md:p-8">
            <Swiper
              className="relative"
              wrapperClass="relative"
              effect="fade"
              fadeEffect={{ crossFade: true }}
              loop={true}
              spaceBetween={24}
              slidesPerView={1}
              navigation={{
                nextEl: ".button-next-comprehensive",
                prevEl: ".button-prev-comprehensive",
              }}
              pagination={{
                el: ".comprehensive-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="${className}"></span>`;
                },
              }}
              onSwiper={(swiper) => setSwiperSlider(swiper)}
              modules={[Pagination, Navigation, EffectFade]}>
              {comprehensiveCareSection.sliderProgram.map((slide, indexSlide) => (
                <SwiperSlide
                  key={slide.title + indexSlide}
                  className="!flex flex-col gap-8 text-site-text-blue-1 sm:!h-auto sm:justify-between lg:flex-row lg:gap-0">
                  <div className="order-2 w-full lg:order-1">
                    <div className="pr-0 md:pr-18 lg:max-w-[755px] lg:pr-6">
                      <h3 className="mb-2 text-site-text-32-38 capitalize">{slide.title}</h3>
                      <p className="relative mb-4 w-full text-left text-site-text-18-27 font-light">
                        {slide.description}
                      </p>
                    </div>
                    <SliderPaginationButtons className="h-[64px] overflow-hidden">
                      <div
                        className={classNames(
                          "btn-container absolute flex min-h-[59px] w-full flex-row gap-2",
                          indexSlide === 0 || isMobile
                            ? programsTabsPosition(indexSlide)
                            : "-left-[calc(22%)]"
                        )}>
                        {comprehensiveCareSection.sliderProgram
                          .slice(
                            indexSlide === 0 || isMobile ? indexSlide : indexSlide - 1,
                            comprehensiveCareSection.sliderProgram.length
                          )
                          .map((slide, index) => (
                            <button
                              key={`btn-${index}`}
                              onClick={() =>
                                goToSlide(
                                  slide?.tabs.length || 0,
                                  indexSlide === 0 || isMobile ? indexSlide + index : indexSlide + index - 1
                                )
                              }
                              className={classNames(
                                "btn-pagination flex flex-row gap-2 border-0 active:right-0",
                                indexSlide === 0 || isMobile
                                  ? index === 0
                                    ? "active"
                                    : ""
                                  : index === 1
                                    ? "active"
                                    : "",
                                "slide-" + index
                              )}>
                              {slide.tabs.map((tab: string, idx: number) => (
                                <span
                                  key={`span-${indexSlide + index}-${idx}`}
                                  className={classNames(
                                    "flex min-w-[164px] items-center justify-center text-nowrap rounded-[10px] border-[2px] border-site-text-blue-2 p-4 text-site-text-16-24 font-light transition-all duration-300 hover:!border-transparent hover:!bg-site-text-blue-2/50 hover:text-white"
                                  )}>
                                  {tab}
                                </span>
                              ))}
                            </button>
                          ))}
                      </div>
                    </SliderPaginationButtons>
                  </div>
                  <div className="relative z-10 order-1 mx-auto sm:min-h-[263px] lg:order-2 lg:mx-0 lg:shrink-0">
                    <Image
                      src={`/images/site/SimpleMSK/${slide.imageUrl}`}
                      alt={slide.title}
                      width="367"
                      height="263"
                      className="h-full w-auto self-center rounded-2xl object-cover shadow-icon-card"
                    />
                  </div>
                </SwiperSlide>
              ))}
              <div className="mt-6 flex flex-row items-center justify-between">
                <SliderPaginationDark className="comprehensive-pagination"></SliderPaginationDark>
                <div className="relative z-30 flex flex-row gap-4">
                  <div className="button-prev-comprehensive group hover:cursor-pointer">
                    <IconArrow
                      btnClass="border-site-text-blue-1 group-hover:bg-site-text-blue-1"
                      iconClass="text-site-text-blue-1 group-hover:text-site-text-white text-extralight text-[18px] transform rotate-180"
                    />
                  </div>
                  <div className="button-next-comprehensive group hover:cursor-pointer">
                    <IconArrow
                      btnClass="border-site-text-blue-1 group-hover:bg-site-text-blue-1"
                      iconClass="text-site-text-blue-1 group-hover:text-site-text-white text-extralight text-[18px]"
                    />
                  </div>
                </div>
              </div>
            </Swiper>
          </div>
          <div className="virtualMSKCare mb-4 md:mb-16">
            <Image
              src={`/images/site/SimpleMSK/${virtualMSKCareSection.imageUrl}`}
              alt={virtualMSKCareSection.title}
              width="326"
              height="253"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              className="mb-4 block max-h-[440px] min-h-[260px] w-full rounded-2xl object-cover shadow-icon-card md:hidden"
            />
            <h2 className="mb-2 text-site-text-24-28 font-medium md:text-site-text-32-35">
              {virtualMSKCareSection.title}
            </h2>
            <p className="mb-4 text-site-text-16-24 font-light md:mb-16 md:text-site-text-18-27">
              {virtualMSKCareSection.description}
            </p>
            <div className="flex flex-col gap-8 md:flex-row">
              <Image
                src={`/images/site/SimpleMSK/${virtualMSKCareSection.imageUrl}`}
                sizes="100vw"
                alt={virtualMSKCareSection.title}
                width="479"
                height="464"
                className="hidden max-h-[440px] max-w-[479px] rounded-2xl object-cover shadow-icon-card md:block"
              />

              <div className="max-w-[655px]">
                <AccordionIcon
                  items={virtualMSKCareSection.cards.map((item) => ({
                    title: item.title,
                    iconTitle: item.imageUrl || "",
                    content: item.description,
                  }))}
                  iconType="chevron"
                  classButton="rounded-[10px] "
                  classContainer="flex flex-col gap-4"
                  classWrapper="shadow-icon-card"
                />
              </div>
            </div>
          </div>
          <div className="personCareSection mb-4 md:mb-16">
            <Image
              src={`/images/site/SimpleMSK/${personCareSection.imageUrl}`}
              sizes="100vw"
              alt={personCareSection.title}
              width="326"
              height="253"
              className="mb-4 block min-h-[260px] w-full rounded-2xl object-cover shadow-icon-card md:hidden"
            />
            <h2 className="mb-2 text-site-text-24-28 font-medium md:text-site-text-32-35">
              {personCareSection.title}
            </h2>
            <p className="mb-4 text-site-text-16-24 font-light md:mb-16 md:text-site-text-18-27">
              {personCareSection.description}
            </p>
            <div className="flex flex-col gap-8 md:flex-row">
              <div className="max-w-[655px]">
                <AccordionIcon
                  items={personCareSection.cards.map((item) => ({
                    title: item.title,
                    iconTitle: item.imageUrl || "",
                    content: item.description,
                  }))}
                  iconType="chevron"
                  classButton="rounded-[10px] "
                  classContainer="flex flex-col gap-4"
                  classWrapper="shadow-icon-card"
                />
              </div>
              <Image
                src={`/images/site/SimpleMSK/${personCareSection.imageUrl}`}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                alt={personCareSection.title}
                width="479"
                height="464"
                className="hidden max-h-[440px] max-w-[479px] rounded-2xl object-cover shadow-icon-card md:block"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-orange-10 py-8 sm:py-10 md:py-16">
        <div className="container !max-w-[1170px]">
          <h2 className="mb-6 text-site-text-28-33 font-medium text-orange-1 md:text-site-text-48-57">
            {redefiningSection.title}
          </h2>
          <p className="mb-4 text-center text-site-text-16-24 font-extralight md:mb-8 md:text-site-text-24-33">
            {redefiningSection.description}
          </p>
          <div>
            <AccordionVertical
              items={redefiningSection.cards.map((card) => ({
                title: card.button.title,
                iconTitle: card.button.icon,
                content: {
                  imageUrl: card.content.imageUrl,
                  description: card.content.description,
                },
              }))}
              itemOpen={0}
              classContainer=" gap-4"
              classWrapper=""
            />
          </div>
        </div>
      </div>
      <div className="bg-white py-8 sm:py-10 md:py-16">
        <div className="container">
          <div className="flex flex-col gap-4 sm:gap-15 md:flex-row md:gap-30 lg:gap-40">
            <div className="max-w-[659px] text-body-1">
              <h2 className="mb-2 text-site-text-16-24 font-light md:text-site-text-18-27">
                {swissReFoundationSection.topTitle}
              </h2>
              <h3 className="mb-4 text-site-text-24-28 md:text-site-text-32-35">
                {swissReFoundationSection.title}
              </h3>
              <ContainerDeacription className="text-site-text-16-24 font-light md:text-site-text-18-27">
                {parse(swissReFoundationSection.description)}
              </ContainerDeacription>
            </div>
            <div className="flex shrink-0 flex-wrap items-center justify-center gap-4 md:gap-8">
              <Image
                src={`/images/site/SimpleMSK/${swissReFoundationSection.imageLogo1}`}
                alt="swissReFoundation"
                width="239"
                height="78"
                className="max-h-[51px] w-auto sm:max-h-[78px]"
              />
              <Image
                src={`/images/site/SimpleMSK/${swissReFoundationSection.imageLogo2}`}
                alt="E_SDG_logo_UN"
                width="159"
                height="136"
                className="max-h-[89px] w-auto sm:max-h-[136px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-site-background-blue py-8 sm:py-10 md:py-16">
        <div className="container text-white">
          <h2 className="md:site-text-48-57 mb-6 text-center text-site-text-28-33">
            {realResultsSection.title}
          </h2>
          <ContainerSlider>
            <Swiper
              spaceBetween={24}
              slidesPerView={1.4}
              loop={true}
              breakpoints={{
                768: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 4,
                },
                1280: {
                  slidesPerView: 5,
                },
              }}
              navigation={{
                nextEl: ".button-next",
                prevEl: ".button-prev",
              }}
              pagination={{
                el: ".posts-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="${className}"></span>`;
                },
              }}
              modules={[Pagination, Navigation]}>
              {realResultsSection.sliderResults.map((slide, indexSlide) => (
                <SwiperSlide
                  key={`slide_${indexSlide}`}
                  className="!flex !h-auto flex-col items-center rounded-[24px] bg-white px-6 py-14 text-site-text-blue-1 shadow-icon-card">
                  <h3 className="mb-2 w-full text-site-text-48-57">{slide.title}</h3>
                  <p className="relative w-full text-left text-site-text-18-27 font-bold capitalize">
                    {slide.description}
                    <span className="relative bottom-2.5 ml-1 text-site-text-10-11 font-light">
                      {slide.ladelNumber}
                    </span>
                  </p>
                </SwiperSlide>
              ))}
            </Swiper>
          </ContainerSlider>
          <div className="mt-6 flex flex-row items-center justify-between">
            <div className="posts-pagination"></div>
            <div className="relative z-30 flex flex-row gap-4">
              <div className="button-prev group hover:cursor-pointer">
                <IconArrow
                  btnClass="border-site-text-white group-hover:bg-site-text-white"
                  iconClass="text-site-text-white group-hover:text-site-text-blue-1 text-extralight text-[18px] transform rotate-180"
                />
              </div>
              <div className="button-next group hover:cursor-pointer">
                <IconArrow
                  btnClass="border-site-text-white group-hover:bg-site-text-white"
                  iconClass="text-site-text-white group-hover:text-site-text-blue-1 text-extralight text-[18px] "
                />
              </div>
            </div>
          </div>
          <div className="hidden sm:block">
            <h3 className="mb-2 text-site-text-12-18 font-bold capitalize">sources:</h3>
            <div className="flex flex-col gap-1">
              {realResultsSection?.sources?.map((source, index) => (
                <p key={`sources_${index}`} className="flex flex-row text-site-text-8-12">
                  <span className="mr-1">{index + 1}.</span>
                  <span>{source}</span>
                </p>
              ))}
            </div>
          </div>

          <Accordion
            items={[
              {
                question: "Sources",
                answer: `<div class="flex flex-col gap-1">${sourcesMapResult}</div>`,
              },
            ]}
            colorChevron="white"
            classWrapper="text-site-text-12-18 font-bold capitalize !w-fit !p-0"
            classButton="text-white !w-fit !p-0 items-center !border-0"
            classContentWrapper="bg-transparent text-white !p-0"
            classContainer="sm:hidden block"
          />
        </div>
      </div>
      {/* APTA Section */}
      <div className="bg-site-background-gray py-[73px]">
        <div className="container flex flex-col gap-6 md:flex-row">
          <Link
            href="https://www.apta.org/your-practice/practice-models-and-settings/digital-health-technology/apta-digital-health-transparency-campaign"
            aria-label="APTA Logo"
            target="_blank">
            <Image
              src={`/images/site/SimpleMSK/${aptaSection.imageUrl}`}
              alt="APTA Logo"
              width="171"
              height="126"
              className="mx-auto max-h-[126px] w-fit object-cover md:mx-0 md:w-auto"
            />
          </Link>
          <p className="max-w-[970px] text-site-text-18-27 font-light text-site-background-blue">
            {aptaSection.description}
          </p>
        </div>
      </div>
      <div className="bg-site-background-white py-8 sm:py-10 md:py-16">
        <div className="container">
          <h2 className="mb-6 text-center text-site-text-28-33 font-medium md:text-site-text-48-57">
            {realPeopleSection.title}
          </h2>
          <p className="mb-4 text-center text-site-text-16-24 font-light md:mb-6 md:text-site-text-24-33">
            {realPeopleSection.description}
          </p>
          <SliderContainerRealPeople>
            <Swiper
              spaceBetween={20}
              slidesPerView={2.2}
              // centeredSlides={true}
              loop={true}
              initialSlide={1}
              breakpoints={{
                576: {
                  slidesPerView: 2.2,
                },
                768: {
                  slidesPerView: 2.5,
                },
                1024: {
                  initialSlide: 1,
                  slidesPerView: 3.5,
                },
                1280: {
                  initialSlide: 1,
                  slidesPerView: 3,
                },
              }}
              navigation={{
                nextEl: ".real-people-button-next",
                prevEl: ".real-people-button-prev",
              }}
              pagination={{
                el: ".real-people-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="${className}"></span>`;
                },
              }}
              onChange={(swiper) => {}}
              modules={[Pagination, Navigation]}>
              {realPeopleSection.slideItems.map((slide, indexSlide) => (
                <SwiperSlide
                  key={`realPeople_${indexSlide}`}
                  className="group !flex !h-auto flex-col items-center justify-center shadow-card-1">
                  <div
                    className="video-wrapper relative flex min-h-[155px] w-full items-center justify-center overflow-hidden rounded-[7px] bg-cover bg-center bg-no-repeat sm:min-h-[247px] md:min-h-[330px] lg:min-h-[400px]"
                    style={{
                      backgroundImage: `url(/images/site/SimpleMSK/${slide.preview})`,
                    }}>
                    <a
                      href={slide.link}
                      target="_blank"
                      className={classNames(
                        "slide-btn flex h-8 w-8 items-center justify-center rounded-full bg-black-2/10 transition-all duration-300 group-hover:h-9 group-hover:w-9 group-hover:bg-black-2/50"
                      )}
                      title="Watch video">
                      <i className="icon-play text-[28px] text-white opacity-50 transition-all duration-300 group-hover:text-[32px] group-hover:opacity-100" />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderContainerRealPeople>
          <div className="mt-6 flex flex-row items-center justify-between">
            <SliderPaginationDark className="real-people-pagination"></SliderPaginationDark>
            <div className="relative z-30 flex flex-row gap-4">
              <div className="real-people-button-prev group hover:cursor-pointer">
                <IconArrow
                  btnClass="border-site-text-blue-1 group-hover:bg-site-text-blue-1"
                  iconClass="text-site-text-blue-1 group-hover:text-site-text-white text-extralight text-[18px] transform rotate-180"
                />
              </div>
              <div className="real-people-button-next group hover:cursor-pointer">
                <IconArrow
                  btnClass="border-site-text-blue-1 group-hover:bg-site-text-blue-1"
                  iconClass="text-site-text-blue-1 group-hover:text-site-text-white text-extralight text-[18px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-white"
        classContentWrapper="max-w-[650px]"
        classTitle="items-center gap-4"
        classDescription="text-center md:text-left"
        classButton="bg-site-btn-orange text-white hover:bg-site-btn-orange-hover w-full md:w-fit"
      />
    </>
  );
};

export default SimpleMSKPageComponent;
