import { Accordion } from "@/components/site/parts/Accordion";
import { CollToAction } from "@/components/site/parts/CollToAction";
import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import { IconTextCardWide } from "@/components/site/parts/IconTextCardWide";
import { ImageTextSection } from "@/components/site/parts/ImageTextSection";
import { ImageTextSectionContent } from "@/components/site/parts/ImageTextSection/types";
import { MainSection } from "@/components/site/parts/MainSection";
import { MemberReportedCard } from "@/components/site/parts/MemberReportedCard";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

import {
  MainSectionProps,
  EmpathyBeyondSectionContent,
  MemberReportedOutcomesContent,
  RespondToEmployeeContent,
  FAQSectionContent,
  WaysToConnectContent,
} from "./types";

interface DynamicPageProps {
  pageData: PageActive;
}
const SimpleEAPPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();

  const sections = [
    { key: "mainSection", type: "MainSectionProps" },
    { key: "imageTextSection", type: "ImageTextSectionContent" },
    { key: "empathyBeyondSection", type: "EmpathyBeyondSectionContent" },
    { key: "memberReportedOutcomes", type: "MemberReportedOutcomesContent" },
    { key: "respondToEmployeeContent", type: "RespondToEmployeeContent" },
    { key: "faqSection", type: "FAQSectionContent" },
    { key: "waysToConnectSction", type: "WaysToConnectContent" },
    { key: "collToActionSection", type: "CollToActionContent" },
  ];

  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`SimpleEAP.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    imageTextSection,
    empathyBeyondSection,
    memberReportedOutcomes,
    respondToEmployeeContent,
    faqSection,
    waysToConnectSction,
    collToActionSection,
  } = sectionContents as {
    mainSection: MainSectionProps;
    imageTextSection: ImageTextSectionContent;
    empathyBeyondSection: EmpathyBeyondSectionContent;
    memberReportedOutcomes: MemberReportedOutcomesContent;
    respondToEmployeeContent: RespondToEmployeeContent;
    faqSection: FAQSectionContent;
    waysToConnectSction: WaysToConnectContent;
    collToActionSection: CollToActionContent;
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };
  return (
    <>
      <MainSection
        imgUrl={`/images/site/SimpleEAP/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper="order-2"
        classImage="order-1 max-h-[260px] xs:max-h-[610px] md:min-h-[609px] md:max-w-[609px] object-top"
        position="right">
        <Image
          className="mb-2 h-full max-h-[33px] w-auto object-cover"
          src={`/images/site/SimpleEAP/st-logo_brands.svg`}
          alt="Simple Therapy"
          width="240"
          height="33"
        />
        <h1 className="mb-4 md:mb-10">
          <span className="relative inline text-site-text-28-33 font-normal capitalize text-site-text-blue-1 sm:text-site-text-38-38 md3:text-site-text-56-67">
            {mainSection.title.line}
          </span>
          <span className="relative inline-flex text-site-text-28-33 font-normal capitalize text-site-text-blue-1 before:absolute before:bottom-[-20px] before:right-[0px] before:h-[24px] before:w-full before:bg-[url('/images/site/SimpleEAP/decor_line.svg')] before:bg-contain before:bg-no-repeat sm:text-site-text-38-38 md3:text-site-text-56-67 md3:before:bottom-[-10px]">
            {mainSection.title.lineDecoration}
          </span>
        </h1>
        <p className={classNames("rounded-[24px] bg-gray-4 p-6 text-site-text-18-27 font-light md:p-10")}>
          {mainSection.description}
        </p>
      </MainSection>
      <ImageTextSection
        imageUrl={`/images/site/SimpleEAP/${imageTextSection.imageUrl}`}
        position="left"
        classImage="max-h-[330px] w-auto rounded-[24px] md:max-w-[660px] md:max-h-[440px]"
        classNameWrapper="py-8 md:py-32 text-white bg-site-background-blue-1"
        classTitle="max-w-full">
        <h2
          className={classNames(
            "mb-4 max-w-[500px] text-site-text-28-33 font-medium md:mb-10 md:text-site-text-48-57"
          )}>
          {imageTextSection.title}
        </h2>
        <p className={classNames("max-w-[515px] text-site-text-16-24 font-[300] md:text-site-text-18-27")}>
          {imageTextSection.description}
        </p>
      </ImageTextSection>
      <div className="bg-site-background-white py-8 md:py-16">
        <div className="container">
          <h2 className="mb-2 text-site-text-24-28 md:text-site-text-32-38">{empathyBeyondSection.title}</h2>
          <p className="mb-6 text-site-text-16-24 md:mb-16 md:text-site-text-18-27">
            {empathyBeyondSection.description}
          </p>
          <div className="flex flex-col gap-6 md:flex-row md:gap-15">
            <Image
              className="max-h-[330px] w-auto rounded-[24px] object-cover md:max-h-[440px] md:max-w-[660px] lg:min-w-[551px]"
              src={`/images/site/SimpleEAP/${empathyBeyondSection.imageUrl}`}
              width={551}
              height={472}
              alt={empathyBeyondSection.title}
            />
            <div className="flex flex-col gap-4">
              {empathyBeyondSection.features.map((item, idx) => (
                <IconTextCardWide
                  key={idx}
                  card={{ title: item.title, icon: item.icon }}
                  classText="text-site-text-blue-3"
                  classIcon="h-[48px] w-[48px] p-2.5 bg-gray-4"
                  classWrapper="py-4 px-2.5 md:py-3 md:px-8 flex-row gap-4 min-h-[80px] shadow-icon-card"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-6 md:py-16">
        <div className="container">
          <h2 className="mb-2.5 text-center text-site-text-28-33 md:mb-6 md:text-site-text-32-35">
            {memberReportedOutcomes.title}
          </h2>
          <div className="mb-0 md:mb-6">
            <div className="mb-6 flex flex-wrap content-start gap-8">
              {memberReportedOutcomes.resultsCard.slice(0, 3).map((item, index) => (
                <MemberReportedCard
                  key={index}
                  content={item}
                  classImage="w-full"
                  classCardWrapper="flex-wrap flex-[1_1_250px]"
                />
              ))}
            </div>
            <div className="flex flex-wrap content-start gap-6 md:flex-nowrap">
              {memberReportedOutcomes.resultsCard.slice(3).map((item, index) => (
                <MemberReportedCard
                  key={index}
                  content={item}
                  classContentWrapper="self-start lg:self-center"
                  classImage="lg:max-w-[352px] w-full"
                  classCardWrapper="flex-wrap flex-[1_1_350px]"
                />
              ))}
            </div>
          </div>

          <div className="hidden md:block">
            <h2 className="mb-2 text-site-text-12-18">{memberReportedOutcomes.sources.title}</h2>
            <p
              className="max-w-[335px] text-site-text-8-12"
              dangerouslySetInnerHTML={{ __html: memberReportedOutcomes.sources.description }}></p>
          </div>
        </div>
      </div>
      <div className="bg-site-background-gray py-8 md:py-16">
        <div className="container max-w-[1152px]">
          <h2 className="mb-2 text-left text-site-text-24-28 md:text-center md:text-site-text-32-38">
            {respondToEmployeeContent.title}
          </h2>
          <p className="mb-4 text-left text-site-text-16-24 font-light md:mb-8 md:text-center md:text-site-text-18-27">
            {respondToEmployeeContent.description}
          </p>
          <div className="mx-auto grid max-w-[1150px] grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
            {respondToEmployeeContent?.features?.map((item, idx) => (
              <IconTextCardWide
                key={idx}
                card={{ title: item.title, icon: item.icon }}
                classText="text-site-text-blue-3 text-center md:max-w-[216px] md:mx-auto"
                classIcon="h-[64px] w-[64px] p-4 bg-gray-4"
                classWrapper="py-3 sm:py-1 px-2 sm:min-h-[215px] flex-row sm:flex-col sm:justify-center gap-2.5 bg-white shadow-icon-card"
              />
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white py-8 md:py-16">
        <div className="container">
          <h2 className="mb-2 text-center text-site-text-28-33 md:mb-6 md:text-site-text-48-57">
            {waysToConnectSction.title}
          </h2>
          <p className="mb-6 text-left text-site-text-16-23 font-light md:mb-16 md:text-center md:text-site-text-18-27">
            {waysToConnectSction.description}
          </p>
          <Image
            src={`/images/site/SimpleEAP/${waysToConnectSction.imageUrl}`}
            width={324}
            height={265}
            alt={waysToConnectSction.title}
            className="mb-6 block w-full rounded-[24px] object-cover md:hidden"
          />
          <div className="grid grid-cols-1 gap-6 md:grid-cols-[minmax(400px,_1.3fr)_1fr]">
            <div className="order-1 rounded-[24px] bg-site-background-blue-1 px-4 pb-8 pt-5 md:pb-6">
              <h3 className="mb-5 flex flex-row items-center gap-4 text-site-text-24-28 font-extrabold text-white md:mb-8 md:text-site-text-32-35">
                <Image
                  src={"/images/icon/device.svg"}
                  width={32}
                  height={32}
                  alt="device"
                  loading="lazy"
                  className="h-8 w-8"
                />
                {waysToConnectSction.digital.title}
              </h3>
              {waysToConnectSction.digital.description && (
                <p className="text-site-text-16-24 font-light text-white">
                  {waysToConnectSction.digital.description}
                </p>
              )}
              <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-4">
                {waysToConnectSction.digital.items.map((item, idx) => (
                  <IconTextCardWide
                    key={idx}
                    card={{ title: item.title, icon: item.icon }}
                    classText="text-white md:!text-site-text-18-27"
                    classIcon="h-[42px] w-[42px] p-2.5 bg-gray-4"
                    classWrapper="flex-row gap-4"
                  />
                ))}
              </div>
            </div>
            <div className="relative order-3 rounded-[24px] bg-site-background-gray px-4 pb-0 pt-5 md:order-2 md:pb-6">
              <h3 className="mb-5 flex flex-row items-center gap-4 text-site-text-24-28 font-extrabold text-site-text-blue-3 md:mb-8 md:text-site-text-32-35">
                <Image
                  src={"/images/icon/account-network-outline.svg"}
                  loading="lazy"
                  width={32}
                  height={32}
                  alt="device"
                  className="h-8 w-8"
                />
                {waysToConnectSction.other.title}
              </h3>
              {waysToConnectSction.digital.description && (
                <p className="text-site-text-16-24 font-light text-site-text-blue-3">
                  {waysToConnectSction.other.description}
                </p>
              )}
              <div className="mb-5 grid grid-cols-1 gap-5 md:gap-4">
                {waysToConnectSction.other.items.map((item, idx) => (
                  <IconTextCardWide
                    key={idx}
                    card={{ title: item.title, icon: item.icon }}
                    classText="text-site-text-blue-3 md:!text-site-text-18-27"
                    classIcon="h-[42px] w-[42px] p-2.5 bg-site-background-blue-1"
                    classWrapper="flex-row gap-4"
                  />
                ))}
              </div>
              <Image
                src={"/images/site/SimpleEAP/mobile_graphic.webp"}
                loading="lazy"
                width={324}
                height={265}
                alt="device"
                className="relative bottom-0 left-2/4 h-[250px] w-auto -translate-x-2/4 object-cover md:absolute md:h-full md:max-h-[210px]"
              />
            </div>
            <div
              className={`order-3 hidden rounded-[24px] bg-[url("/images/site/SimpleEAP/Benefits_Card_d.webp")] bg-cover bg-[10%_10%] px-4 pb-8 pt-5 md:block md:pb-6`}></div>
            {/* <div
              className={`order-3 hidden rounded-[24px] bg-[url("/images/site/SimpleEAP/Benefits_Card_m.webp")] bg-cover bg-[10%_10%] px-4 pb-8 pt-5 md:block md:pb-6`}></div> */}
            <div className="relative order-2 rounded-[24px] bg-site-background-gray px-4 pb-8 pt-5 md:order-4 md:min-h-[175px] md:pb-6">
              <h3 className="mb-5 flex flex-row items-center gap-4 text-site-text-24-28 font-extrabold text-site-text-blue-3 md:mb-8 md:text-site-text-32-35">
                <Image
                  src={"/images/icon/phone-outlined.svg"}
                  loading="lazy"
                  width={32}
                  height={32}
                  alt="device"
                  className="h-8 w-8"
                />
                {waysToConnectSction.contact.title}
              </h3>
              {waysToConnectSction.contact.description && (
                <p className="text-site-text-16-24 font-light text-site-text-blue-3">
                  {waysToConnectSction.other.description}
                </p>
              )}
              <div className="grid grid-cols-1 gap-10 md:gap-4">
                {waysToConnectSction.contact.items.map((item, idx) => (
                  <IconTextCardWide
                    key={idx}
                    card={{ title: item.title, icon: item.icon }}
                    classText="text-site-text-blue-3 md:!text-site-text-18-27"
                    classIcon="h-[42px] w-[42px] p-2.5 bg-site-background-blue-1"
                    classWrapper="flex-row gap-4"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-site-background-gray"
        classTitle="items-center gap-4"
        classDescription="text-center md:text-left"
        classButton="bg-site-background-blue-1 text-white hover:bg-site-background-blue-1-hover w-full md:w-fit"
      />
      <div className="bg-white py-8 md:py-16">
        <div className="container">
          <h2 className="mb-4 text-center text-site-text-28-33 md:mb-6 md:text-site-text-48-57">
            {faqSection.title}
          </h2>
          <p className="mb-4 text-center text-site-text-16-24 font-light md:mb-16 md:text-site-text-18-27">
            {faqSection.description}
          </p>
          <div className="accordion">
            <Accordion
              items={faqSection.faqItems}
              classContainer="md:rounded-[10px] md:border-[1px] md:border-gray-5"
              classButton="border-b-[1px] border-gray-5 py-5.5 md:px-6 text-site-text-16-24 md:text-site-text-18-27 "
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleEAPPageComponent;
