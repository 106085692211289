import classNames from "classnames";
import parse from "html-react-parser";
import Image from "next/image";
import React from "react";

import { AccordionItemProps } from "./types";

export const AccordionItem = ({
  title,
  iconTitle,
  content,
  isActive,
  iconType,
  onToggle,
  classWrapper,
  classButton,
  classContentWrapper,
}: AccordionItemProps) => {
  return (
    <div className="flex flex-row">
      <div
        className={classNames(
          "w-full max-w-[640px] overflow-hidden text-orange-1 transition-all duration-300 hover:border-orange-1 md:rounded-[24px] md:border-[1px]",
          classWrapper,
          isActive ? "md:border-orange-1" : "md:border-transparent"
        )}>
        <button
          className={classNames(
            "flex w-full flex-row justify-between rounded-[24px] border-[1px] bg-white px-6 py-4 focus:outline-none focus:ring-0 active:outline-none active:ring-0 md:mb-0 md:rounded-[0px]",
            classButton,
            isActive ? "border-orange-1 md:border-transparent" : "border-transparent"
          )}
          onClick={onToggle}>
          <h3
            className={classNames(
              "flex items-center gap-6 text-left text-site-text-18-21 font-normal md:text-site-text-24-33 md:font-medium"
            )}>
            <p className="flex h-12.5 w-12.5 shrink-0 items-center justify-center rounded-full bg-orange-10 p-2 md:h-16 md:w-16">
              {iconTitle && (
                <Image
                  src={`/images/site/SimpleMSK/${iconTitle}`}
                  alt="icon"
                  width={26}
                  height={26}
                  className="h-6.5 w-auto shrink-0 object-cover"
                />
              )}
            </p>
            {title}
          </h3>
        </button>
        <div
          className={classNames(
            "grid min-h-0 overflow-hidden transition-all duration-300 md:hidden",
            isActive ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
          )}>
          <div className="min-h-0">
            <Image
              src={`/images/site/SimpleMSK/${content.imageUrl}`}
              alt={title}
              width={502}
              height={417}
              className="mb-6 mt-4 w-full rounded-[24px] object-cover"
            />
            <div
              className={classNames(
                "text-center text-site-text-16-24 font-[100] text-site-background-blue",
                classContentWrapper
              )}>
              {parse(content.description)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
