import { PageContainer } from "@/styles/site/posts/style-md-pages";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

const AccessibilityStatementPageComponent = ({ post }: any) => {
  const containerMenu = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [activeSection, setActiveSection] = useState(0);
  const [leftPosition, setLeftPosition] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (container.current) {
        const containerTop = container.current.offsetTop;
        const scrollPosition = window.scrollY;

        containerMenu.current &&
        containerMenu.current.scrollHeight > window.innerHeight &&
        activeSection > post.sectionTitles.length / 2
          ? containerMenu.current?.style.setProperty("top", `-${containerMenu.current?.scrollHeight / 2}px`)
          : containerMenu.current?.removeAttribute("style");

        if (scrollPosition < containerTop) {
          setLeftPosition(0);
          setIsScrolling(false);
        } else {
          setLeftPosition(activeSection * 210);
          setIsScrolling(true);
        }
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = post.sectionTitles.findIndex((title: { title: string; dataTitle: string }) => {
              return title.dataTitle === entry.target.textContent;
            });
            setActiveSection(index);
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px", threshold: 0 }
    );
    const elements = document.querySelectorAll('[data-target="observe"]');
    elements.forEach((element) => observer.observe(element));
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, [post?.sectionTitles, container, activeSection]);

  const scrollToSection = (href: string) => {
    const element = document.querySelector(href);
    element?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <>
      <div ref={container} className="container flex flex-col gap-12 md:flex-row">
        <div className="relative md:w-[70%]">
          <div
            className={classNames(
              "-mt-[106px] bg-site-background-white pt-[106px] transition-all duration-300 xs:mt-0 md:relative md:top-auto md:pt-0"
            )}>
            <h1 className="mb-6 max-w-[630px] text-site-text-32-38 font-bold md:mb-11 md:text-site-text-56-67">
              {post?.title}
            </h1>
            <p className="mb-6 text-site-text-18-27 font-extralight">{post?.date}</p>
            <p className="mb-6 text-site-text-16-24 font-extralight md:mb-12.5 md:text-site-text-18-27">
              {post?.description}
            </p>
          </div>
          <div>
            <div
              className={classNames(
                "sticky top-0 mb-8 block h-fit overflow-hidden bg-site-background-white transition-all duration-300 md:hidden",
                isScrolling ? "pt-[106px]" : "pt-0"
              )}>
              <ul
                className="relative flex flex-row transition-all duration-300"
                style={{ left: `${-leftPosition}px` }}>
                {post?.sectionTitles.length > 0 &&
                  post?.sectionTitles.map(
                    (item: { title: string; dataTitle: string; href: string }, index: number) => (
                      <li
                        className={classNames(
                          "flex flex-[0_0_210px] items-center border-b-[2px] px-6 py-2 text-site-text-16-20 font-light transition-all duration-300",
                          activeSection === index
                            ? "border-b-primary-3 text-primary-3"
                            : "border-b-gray-7 opacity-30"
                        )}
                        key={index}
                        data-title={item.dataTitle}>
                        <button className="text-left" onClick={() => scrollToSection(item.href)}>
                          {item.title}
                        </button>
                      </li>
                    )
                  )}
              </ul>
            </div>
            <PageContainer dangerouslySetInnerHTML={{ __html: post?.contentHtml }} />
          </div>
        </div>
        <div
          ref={containerMenu}
          className={classNames(
            "sticky hidden h-fit max-w-[310px] transition-all duration-300 md:block",
            isScrolling ? "top-[0px] pt-[100px]" : "top-[200px] pt-[180px]"
          )}>
          <ul>
            {post?.sectionTitles.length > 0 &&
              post?.sectionTitles.map(
                (item: { title: string; dataTitle: string; href: string }, index: number) => (
                  <li
                    className={classNames(
                      "flex-shrink-0 border-l-[3px] py-1 pl-6 text-site-text-18-27 transition-all duration-300",
                      activeSection === index ? "border-l-primary-3 font-bold" : "border-l-gray-7 font-light"
                    )}
                    key={index}
                    data-title={item.dataTitle}>
                    <button className="text-left" onClick={() => scrollToSection(item.href)}>
                      {item.title}
                    </button>
                  </li>
                )
              )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AccessibilityStatementPageComponent;
