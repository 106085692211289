import styled from "styled-components";

export const AccordionContainer = styled.div`
  color: #11374d;
  width: 100%;
  font-family: Avenir;
  h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }
  p,
  ul {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  a {
    color: #0097ce;
  }
  ul {
    list-style-type: disc !important;
    margin-bottom: 16px;
    padding-left: 20px;
  }

  @media (min-width: 768px) {
    h2,
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
