import classNames from "classnames";
import React, { useState } from "react";

import { AccordionItem } from "./AccordionItem";
import { AccordionProps } from "./types";

export const AccordionIcon = ({
  items,
  iconType,
  itemOpen,
  classContainer,
  classWrapper,
  classButton,
  classContentWrapper,
  accordionTitle,
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState(itemOpen);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classNames("", classContainer)}>
      {accordionTitle && (
        <h3 className="my-4 px-2 text-site-text-18-21 md:my-8 md:px-12 md:text-site-text-28-40">
          {accordionTitle}
        </h3>
      )}
      {items.map((item, index) => {
        const btnClass = [classButton].join(" ");
        return (
          <AccordionItem
            key={index}
            title={item.title}
            iconTitle={item.iconTitle}
            iconType={iconType}
            content={item.content}
            buttonText={item.buttonText}
            buttonLink={item.buttonLink}
            itemOpen={itemOpen}
            isActive={activeIndex === index}
            onToggle={() => handleToggle(index)}
            classButton={btnClass}
            classWrapper={classWrapper}
            classContentWrapper={classContentWrapper}
          />
        );
      })}
    </div>
  );
};
