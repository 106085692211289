import { Loader } from "@/components/site/parts/Loader";
import { useSiteContext } from "@/utils/siteContext";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import React, { useEffect, useRef } from "react";

const TestimonialsPageComponent = () => {
  const { isLoader, setIsLoader } = useSiteContext();
  const iFrame = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    setIsLoader(true);
    const script = document.createElement("script");
    script.src = "https://vocalvideo.com/embed/v1/host.js";
    script.defer = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    const iframeElement = iFrame.current;

    const handleLoad = () => {
      setTimeout(() => {
        setIsLoader(false);
      }, 1000);
    };

    if (iframeElement) {
      iframeElement.addEventListener("load", handleLoad);
    }

    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener("load", handleLoad);
      }
    };
  }, [setIsLoader]);

  return (
    <div className="min-h-screen bg-site-background-gray pb-8 md:pb-16">
      {isLoader && <Loader classImageWrapper="h-50 w-50" classContainerWrapper="z-[100] h-screen w-screen" />}
      <div className="container">
        <h1 className="mb-8 text-center">
          <span className="relative mr-2 inline w-fit text-site-text-48-57 text-site-text-blue-2 md:text-site-text-56-67">
            {t("Testimonials.mainSection.title.line")}
          </span>
          <span className="relative inline-flex w-fit text-site-text-48-57 font-normal capitalize text-site-text-blue-2 before:absolute before:bottom-[-15px] before:right-[0px] before:z-0 before:h-[17px] before:w-full before:bg-[url('/images/site/Testimonials/decor_line.svg')] before:bg-cover before:bg-no-repeat after:absolute after:-top-2.5 after:right-[-20px] after:h-[32px] after:w-[28px] after:bg-[url('/images/site/Testimonials/decor.svg')] after:bg-contain after:bg-no-repeat md:text-site-text-56-67 md:before:bottom-[-10px]">
            {t("Testimonials.mainSection.title.lineDecoration")}
          </span>
        </h1>
        <h2 className="mx-auto mb-4 max-w-[860px] text-center text-site-text-18-27 font-extralight md:mb-[30px]">
          {parse(t("Testimonials.mainSection.description"))}
        </h2>
        <iframe
          ref={iFrame}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          allowTransparency={true}
          width="100%"
          frameBorder="0"
          loading="lazy"
          name="vocalvideo_331"
          scrolling="no"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          src="https://vocalvideo.com/embed/v1/galleries/331"
          title="Vocal Video"
          style={{
            background: "transparent",
            position: "relative",
            width: "100%",
            height: "2781px",
            zIndex: 1,
            display: "block",
            overflow: "hidden",
          }}></iframe>
      </div>
    </div>
  );
};

export default TestimonialsPageComponent;
