import { HolidayGreetingsContainer } from "@/styles/site/pages/holidayGreetings";
import React, { useEffect, useRef } from "react";

const Snowfall = () => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const createSnowflake = () => {
      const flakePos = Math.random() * 100;
      const fallingTime = Math.random() * 5 + 5;
      const flakeSize = (Math.floor(Math.random() * 100) + 1) / 100;

      const flakeWrapper = document.createElement("div");
      flakeWrapper.className = "flake-wrapper";
      flakeWrapper.style.width = "15px";
      flakeWrapper.style.height = "15px";
      flakeWrapper.style.left = `${flakePos}%`;
      flakeWrapper.style.transform = `scale(${flakeSize})`;
      flakeWrapper.style.animation = `falling ${fallingTime}s linear infinite`;
      flakeWrapper.innerHTML = `<svg width="40px" height="40px" viewBox="0 0 129.108 140.597" enable-background="new 0 0 129.108 140.597" xml:space="preserve" version="1.1" class="flake"><path fill="#ffFFFF" d="M106.491,83.706l17.706,10.222l-4.067,7.046l-17.88-10.324l4.693,17.494l-7.814,2.096l-6.121-22.916l-0.604-2.402L71,72.519v25.01l1.569,1.627l16.848,16.906l-5.688,5.727L71,108.984V129h-8v-20.221l-12.917,12.807l-5.837-5.727l16.849-16.775L63,97.325V72.519L41.371,84.922l-0.79,2.402l-6.14,22.916l-7.823-2.096l4.688-17.494l-17.882,10.324l-4.068-7.046l17.705-10.222L9.566,79.018l2.096-7.823l23.095,6.188l2.223,0.596l21.66-12.505L37.157,53.071l-2.402,0.644l-22.916,6.14l-2.096-7.823l17.495-4.688L9.358,37.019l4.07-7.046l17.71,10.222l-4.678-17.494l7.842-2.096L40.525,43.7l0.669,2.223L63,58.428V33.622l-1.868-1.758L44.247,15.088l5.8-5.727L63,22.168V2h8v19.963L83.748,9.156l5.668,5.727L72.549,31.79L71,33.418v25.01l21.581-12.505l0.517-2.223l6.188-23.095l7.823,2.096l-4.688,17.494l17.705-10.222l4.068,7.046l-17.882,10.324l17.494,4.688l-2.096,7.823l-22.916-6.14l-2.402-0.644L74.911,65.473L96.57,77.979l2.223-0.596l23.095-6.188l2.096,7.823L106.491,83.706z"/></svg>`;

      if (backgroundRef.current) {
        backgroundRef.current.appendChild(flakeWrapper);
      }
    };

    const removeSnowflake = () => {
      if (backgroundRef.current) {
        const flakes = backgroundRef.current.querySelectorAll(".flake-wrapper");
        flakes.forEach((flake) => {
          const flakePos = flake.getBoundingClientRect().top;
          const bodyHeight = window.innerHeight - 100;
          if (flakePos > bodyHeight) {
            flake.remove();
          }
        });
      }
    };

    const interval = setInterval(() => {
      createSnowflake();
      removeSnowflake();
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <HolidayGreetingsContainer>
      <div ref={backgroundRef} className="background"></div>
    </HolidayGreetingsContainer>
  );
};

export default Snowfall;
