import classNames from "classnames";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import Image from "next/image";
import React from "react";

import { AccordionItemProps } from "./types";

export const AccordionItem = ({
  title,
  content,
  isActive,
  onToggle,
  classWrapper,
  classButton,
  classContentWrapper,
  colorChevron,
  accordionTitle,
}: AccordionItemProps) => {
  const chevron = {
    white: "arrowDown_white.svg",
    black: "arrowDown.svg",
  }[colorChevron || "black"];

  return (
    <div className={classNames("md:px-6", classWrapper)}>
      <button
        className={classNames(
          "flex-rox flex w-full justify-between focus:outline-none focus:ring-0 active:outline-none active:ring-0",
          classButton,
          isActive ? "!border-b-transparent" : ""
        )}
        onClick={onToggle}>
        <h3 className={classNames("mr-2 text-left font-normal")}>{title}</h3>
        <i
          className={classNames(
            "icon-arrowDown self-center transition-all duration-300",
            isActive ? "rotate-180" : ""
          )}></i>
      </button>
      <div
        className={classNames(
          "grid min-h-0 overflow-hidden transition-all duration-300",
          isActive ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        )}>
        <div className="min-h-0">
          <div
            className={classNames(
              "border-gray-5 pb-6 text-site-text-16-24 font-extralight md:px-6",
              classContentWrapper
            )}>
            {parse(content)}
          </div>
        </div>
      </div>
    </div>
  );
};
