import classNames from "classnames";
import Image from "next/image";
import React from "react";

import { MemberReportedCardProps } from "./types";

export const MemberReportedCard = ({
  content,
  classImage,
  classContentWrapper,
  classCardWrapper,
}: MemberReportedCardProps) => {
  return (
    <div
      className={classNames(
        "flex content-start overflow-hidden rounded-[24px] bg-white px-6 py-4 shadow-md md:px-5 md:py-3.5",
        classCardWrapper
      )}>
      <Image
        src={`/images/site/SimpleEAP/${content.imageUrl}`}
        alt={content.title}
        height={314}
        width={352}
        loading="lazy"
        className={classNames(
          "mx-auto h-[314px] w-auto overflow-hidden rounded-[20px] object-cover",
          classImage
        )}
      />
      <div className={classNames("flex-1 p-4", classContentWrapper)}>
        <h3 className="mb-2 text-center text-site-text-24-28 font-bold md:text-site-text-32-38">
          {content.title}
        </h3>
        <h4 className="mb-2.5 text-center text-site-text-18-21 md:mb-3 md:text-site-text-24-28">
          {content.subTitle}
        </h4>
        <p className="text-center text-site-text-16-24 font-light md:text-site-text-18-27">
          {content.description}
        </p>
      </div>
    </div>
  );
};
