import { Button } from "@/components/site/parts/Button";
// import { GetStaticProps, GetStaticPropsContext } from "next";
import { useTranslation } from "next-i18next";
import Image from "next/image";
// import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import React from "react";

interface PageNotFound {
  title: string;
  description: string;
  buttonText?: string;
  buttonLink?: string;
}
const NotFound = () => {
  const { t } = useTranslation("common");
  const pageNotFound: PageNotFound = t("PageNotFound", { returnObjects: true });

  return (
    <>
      <div className="container mx-auto flex !max-w-[562px] flex-col items-center pb-8 md:-mt-9 md:pb-16">
        <Image
          src={`/images/site/404/404.webp`}
          alt="Simple Therapy Coming Soon"
          width="462"
          height="462"
          className="mb-8.5 w-full max-w-[462px] rounded-[21px] object-cover xs2:min-h-[328px] sm:min-h-[462px]"
        />

        <h1 className="mb-4 text-center text-site-text-20-24 font-medium sm:text-site-text-24-33">
          {pageNotFound.title}
        </h1>
        <p className="mb-4 text-center text-site-text-16-24 font-extralight sm:text-site-text-18-27">
          {pageNotFound.description}
        </p>
        <Button
          href={pageNotFound.buttonLink}
          size="large"
          color="orange"
          font="normal"
          textSize="medium"
          rounded="medium"
          classButton="mx-auto w-full gap-2 !min-h-[51px]">
          <span className="text-site-text-16-23 font-light">{pageNotFound.buttonText}</span>
          <span className="relative right-0 text-[20px] transition-all duration-300 group-hover:-right-1 group-hover:text-[24px]">
            <i className="icon-arrowR flex self-center text-[18px] font-[200]"></i>
          </span>
        </Button>
      </div>
    </>
  );
};

export default NotFound;
