import classNames from "classnames";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import Image from "next/image";
import React, { useState } from "react";

import { AccordionItem } from "./AccordionItem";
import { AccordionProps } from "./types";

export const AccordionVertical = ({
  items,
  iconType,
  itemOpen,
  classContainer,
  classWrapper,
  classButton,
  classContentWrapper,
  accordionTitle,
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState(itemOpen);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classNames("flex flex-row", classContainer)}>
      {accordionTitle && (
        <h3 className="my-4 px-2 text-site-text-18-21 md:my-8 md:px-12 md:text-site-text-28-40">
          {accordionTitle}
        </h3>
      )}
      <div className="flex flex-col gap-6 md:flex-[1_1_500px]">
        {items.map((item, index) => {
          const btnClass = [classButton].join(" ");
          return (
            <AccordionItem
              key={index}
              title={item.title}
              iconTitle={item.iconTitle}
              iconType={iconType}
              content={item.content}
              itemOpen={itemOpen}
              isActive={activeIndex === index}
              onToggle={() => handleToggle(index)}
              classButton={btnClass}
              classWrapper={classWrapper}
              classContentWrapper={classContentWrapper}
            />
          );
        })}
      </div>
      <div className="hidden max-w-[502px] md:block">
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(
                "grid min-h-0 overflow-hidden transition-opacity duration-300",
                activeIndex === index
                  ? "visible grid-rows-[1fr] opacity-100"
                  : "invisible grid-rows-[0fr] opacity-0"
              )}>
              <div className="min-h-0">
                <Image
                  src={`/images/site/SimpleMSK/${item.content.imageUrl}`}
                  alt={item.title}
                  width={502}
                  height={417}
                  className="mb-6 rounded-[24px] object-cover"
                />
                <div
                  className={classNames("text-center text-site-text-18-27 font-[350]", classContentWrapper)}>
                  {parse(item.content.description)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
