import { FAQSectionContent } from "@/components/site/pages/SimpleEAP/types";
import { Accordion } from "@/components/site/parts/Accordion";
import { CollToAction } from "@/components/site/parts/CollToAction";
import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import { ImageTextSection } from "@/components/site/parts/ImageTextSection";
import { ImageTextSectionContent } from "@/components/site/parts/ImageTextSection/types";
import { MainSection } from "@/components/site/parts/MainSection";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

import { Button } from "../../parts/Button";
import { MainSectionContent, SectionTitleCardsContent, Card } from "./types";

interface DynamicPageProps {
  pageData: PageActive;
}
export default function SimpleBehavioralPageComponent({ pageData }: DynamicPageProps) {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();

  const sections = [
    { key: "mainSection", type: "MainSectionProps" },
    { key: "contentImageTextSection", type: "ImageTextSectionContent" },
    { key: "benefitAdministrationServices", type: "SectionTitleCardsContent" },
    { key: "conciergeConnectionsToCare", type: "ImageTextSectionContent" },
    { key: "conciergeConnectionsCards", type: "Card" },
    { key: "memberExperience", type: "SectionTitleCardsContent" },
    { key: "faqSection", type: "FAQSectionContent" },
    { key: "collToActionSection", type: "CollToActionContent" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`SimpleBehavioral.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    contentImageTextSection,
    benefitAdministrationServices,
    conciergeConnectionsToCare,
    conciergeConnectionsCards,
    memberExperience,
    faqSection,
    collToActionSection,
  } = sectionContents as {
    mainSection: MainSectionContent;
    contentImageTextSection: ImageTextSectionContent;
    benefitAdministrationServices: SectionTitleCardsContent;
    conciergeConnectionsToCare: ImageTextSectionContent;
    conciergeConnectionsCards: Card[];
    faqSection: FAQSectionContent;
    memberExperience: SectionTitleCardsContent;
    collToActionSection: CollToActionContent;
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };
  return (
    <>
      <MainSection
        imgUrl={`/images/site/SimpleBehavioral/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper="order-2"
        classImage="order-1 max-h-[260px] xs:max-h-[610px] md:min-h-[609px] md:max-w-[609px] object-top"
        position="left">
        <Image
          className="mb-2 h-full max-h-[36px] w-auto object-cover"
          src={`/images/site/SimpleBehavioral/${mainSection.imageBrand}`}
          alt="Simple Therapy"
          width="258"
          height="36"
        />
        <h1 className="mb-4 md:mb-10">
          <span className="relative mr-2 inline text-site-text-32-38 font-normal capitalize text-site-text-blue-2 md3:text-site-text-56-67">
            {mainSection.title.line}
          </span>
          <span className="relative inline-flex w-fit text-site-text-32-38 font-normal capitalize text-site-text-blue-2 before:absolute before:bottom-[-15px] before:right-[0px] before:h-[24px] before:w-full before:bg-[url('/images/site/SimpleBehavioral/decor_main.svg')] before:bg-contain before:bg-no-repeat md3:text-site-text-56-67 md3:before:bottom-[-10px]">
            {mainSection.title.lineDecoration}
          </span>
        </h1>
        <Button
          size="medium"
          color="green"
          font="light"
          rounded="medium"
          eventHandler={handleOpenModal}
          classButton=" mt-4 w-full md:w-fit py-4 md:py-0">
          {mainSection.buttonText}
          <i className="icon-arrowR relative right-0 ml-2 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1"></i>
        </Button>
      </MainSection>
      <div className="bg-gray-9 py-8 md:py-16">
        <ImageTextSection
          imageUrl={`/images/site/SimpleBehavioral/${contentImageTextSection.imageUrl}`}
          position="right"
          classNameWrapper="mb-6 md:mb-16"
          classTitle="max-w-full">
          <h2
            className={classNames(
              "mb-4 max-w-[450px] text-site-text-28-33 font-medium md:mb-10 md:text-site-text-48-57"
            )}>
            {contentImageTextSection.title}
          </h2>
          <p className={classNames("max-w-[515px] text-site-text-16-24 font-[300] md:text-site-text-18-27")}>
            {contentImageTextSection.description}
          </p>
        </ImageTextSection>

        <div className="container">
          <h2 className="mb-6 text-left text-site-text-24-28 text-green md:mb-8 md:text-center md:text-site-text-32-38">
            {benefitAdministrationServices.title}
          </h2>
          <div className="flex flex-col gap-4 md:gap-8">
            {benefitAdministrationServices.cards &&
              benefitAdministrationServices?.cards.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2 rounded-[24px] bg-white p-4 shadow-icon-card md:flex-row md:gap-4 md:p-8">
                  <div className="flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-9">
                    <Image
                      src={`/images/site/SimpleBehavioral/${card.icon}`}
                      alt="icon"
                      width="32"
                      height="32"
                    />
                  </div>
                  <div>
                    <h3 className="mb-4 text-site-text-18-21 font-medium text-green md:text-site-text-24-33">
                      {card.title}
                    </h3>
                    <p className="text-site-text-16-24 font-light md:text-site-text-18-27">
                      {card.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="bg-green py-8 md:py-16">
        <ImageTextSection
          imageUrl={`/images/site/SimpleBehavioral/${conciergeConnectionsToCare.imageUrl}`}
          position="left"
          classNameWrapper="mb-6 md:mb-16"
          classImage="md:max-w-[660px] md:max-h-[440px]"
          classContent="">
          <h2
            className={classNames(
              "mb-4 max-w-[535px] text-site-text-24-28 font-medium text-white md:mb-5 md:text-site-text-32-38"
            )}>
            {conciergeConnectionsToCare.title}
          </h2>
          <p
            className={classNames(
              "text-light mb-4 max-w-[535px] text-site-text-16-24 font-[300] text-white md:text-site-text-18-27"
            )}>
            {conciergeConnectionsToCare["description-line-1"]}
          </p>
          <p
            className={classNames(
              "text-light mb-4 max-w-[535px] text-site-text-16-24 font-[300] text-white md:text-site-text-18-27"
            )}>
            {conciergeConnectionsToCare["description-line-2"]}
          </p>
          <p
            className={classNames(
              "text-light max-w-[535px] text-site-text-16-24 font-[300] text-white md:text-site-text-18-27"
            )}>
            {conciergeConnectionsToCare["description-line-3"]}
          </p>
        </ImageTextSection>
        <div className="container">
          <div className="flex flex-wrap gap-4 md:gap-8">
            {conciergeConnectionsCards.map((card, index) => (
              <div
                key={index}
                className="flex flex-[1_1_300px] flex-row gap-4 rounded-[24px] bg-white px-4 py-4 text-green md:flex-col md:gap-2 md:px-6">
                <div className="flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-9">
                  <Image
                    src={`/images/site/SimpleBehavioral/${card.icon}`}
                    alt="icon"
                    width="32"
                    height="32"
                  />
                </div>
                <h3 className="self-center text-site-text-18-21 font-medium text-green md:self-start md:text-site-text-24-33">
                  {card.title}
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-8 md:py-16">
        <div className="container">
          <h2 className="mb-6 text-center text-site-text-28-36 text-green md:text-site-text-48-57">
            {memberExperience.title}
          </h2>
          <div className="flex flex-col gap-6">
            {memberExperience.cards &&
              memberExperience.cards.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-row gap-6 rounded-[24px] bg-site-text-white px-6 py-8 shadow-icon-card">
                  <div className="hidden h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-9 md:flex">
                    <Image
                      src={`/images/site/SimpleBehavioral/${card.icon}`}
                      alt="icon"
                      width="32"
                      height="32"
                    />
                  </div>
                  <div>
                    <h3 className="md:text-site-text-124-33 mb-4 flex items-center gap-2 text-site-text-18-21 font-medium text-green">
                      <span className="flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-gray-9 md:hidden">
                        <Image
                          src={`/images/site/SimpleBehavioral/${card.icon}`}
                          alt="icon"
                          width="32"
                          height="32"
                        />
                      </span>
                      {card.title}
                    </h3>
                    <p className="text-site-text-16-24 font-light md:text-site-text-18-27">
                      {card.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-gray-9"
        classContentWrapper="max-w-[685px]"
        classTitle="items-center !flex-row gap-2"
        classDescription="text-center md:text-left"
        classButton="bg-green text-white hover:bg-green-1-hover w-full md:w-fit"
      />
      <div className="bg-white py-8 md:py-16">
        <div className="container">
          <h2 className="mb-4 text-center text-site-text-28-33 md:mb-6 md:text-site-text-48-57">
            {faqSection.title}
          </h2>
          <p className="mx-auto mb-4 max-w-[305px] text-center text-site-text-16-24 font-light sm:max-w-full md:mb-16 md:text-site-text-18-27">
            {faqSection.description}
          </p>
          <div className="accordion">
            <Accordion
              items={faqSection.faqItems}
              classContainer="md:rounded-[10px] md:border-[1px] md:border-gray-5"
              classButton="border-b-[1px]  border-gray-5 py-5.5 md:px-6 text-site-text-16-24 md:text-site-text-18-27 "
            />
          </div>
        </div>
      </div>
    </>
  );
}
