import classNames from "classnames";
import React from "react";

import { IconArrowProps } from "./types";

const IconArrow = ({ btnClass, iconClass }: IconArrowProps) => {
  return (
    <button
      className={classNames(
        "flex h-10 w-10 items-center justify-center rounded-full border-[1px] transition-all duration-300",
        btnClass
      )}>
      <i className={classNames("icon-arrowR transition-all duration-300", iconClass)}></i>
    </button>
  );
};

export default IconArrow;
