import classNames from "classnames";
import Image from "next/image";
import React from "react";

import { ImageTextSectionProps } from "./types";

export const ImageTextSection = ({
  imageUrl,
  position,
  classNameWrapper,
  classNameContainer,
  classDescription,
  classTitle,
  classImage,
  classContent,
  children,
}: ImageTextSectionProps) => {
  return (
    <div className={classNames(" ", classNameWrapper)}>
      <div className="container">
        <div
          className={classNames(
            "flex flex-col gap-4 md:gap-16",
            position === "left" ? "md:flex-row-reverse" : "md:flex-row",
            classNameContainer
          )}>
          <div className={classNames("basis-1/2 content-center", classContent)}>{children}</div>
          <Image
            className={classNames("w-auto rounded-[40px] object-cover md:basis-1/2", classImage)}
            src={imageUrl ?? ""}
            alt="Simple Therapy"
            width="609"
            loading="lazy"
            height="695"
          />
        </div>
      </div>
    </div>
  );
};
