import classNames from "classnames";
import React, { useState } from "react";

import { AccordionItem } from "./AccordionItem";
import { AccordionProps } from "./types";

export const Accordion = ({
  items,
  classContainer,
  classWrapper,
  classButton,
  classContentWrapper,
  accordionTitle,
  colorChevron,
}: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index: any) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={classNames("py-3", classContainer)}>
      {accordionTitle && (
        <h3 className="my-4 px-2 text-site-text-18-21 md:my-8 md:px-12 md:text-site-text-28-40">
          {accordionTitle}
        </h3>
      )}
      {items.map((item, index) => {
        const bt = accordionTitle ? "border-t-[1px]" : index === 0 ? "border-t-[1px] md:border-t-0" : "";
        const bb = accordionTitle ? "!border-b-[0px]" : index === items.length - 1 ? "md:border-b-[0px]" : "";
        const bbContent = accordionTitle
          ? "border-b-[0px]"
          : index < items.length - 1
            ? "border-b-[1px]"
            : "";
        const btnClass = [classButton, bt, bb].join(" ");
        const classWrapperContent = [bbContent, classContentWrapper].join(" ");
        return (
          <AccordionItem
            key={`accordion-${index}`}
            title={item.question}
            content={item.answer}
            accordionTitle={accordionTitle}
            colorChevron={colorChevron}
            isActive={activeIndex === index}
            onToggle={() => handleToggle(index)}
            classButton={btnClass}
            classWrapper={classWrapper}
            classContentWrapper={classWrapperContent}
          />
        );
      })}
    </div>
  );
};
