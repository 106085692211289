import {
  ProviderTitleContent,
  CurrentProviderLoginContent,
  VirtualMSKCareContent,
  InPersonCareContent,
  TestimonialsContent,
  FAQContent,
  CollToActionContent,
} from "@/components/site/pages/Provider/types";
import { Accordion } from "@/components/site/parts/Accordion";
import { ImageTextSection } from "@/components/site/parts/ImageTextSection";
import { ImageTextSectionContent } from "@/components/site/parts/ImageTextSection/types";
import { MainSection } from "@/components/site/parts/MainSection";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Button } from "../../parts/Button";
import { CollToAction } from "../../parts/CollToAction";
import { TestimonialsSlider } from "../../parts/TestimonialsSlider";

interface DynamicPageProps {
  pageData: PageActive;
}
const ProviderPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();

  const sections = [
    { key: "mainSection", type: "ProviderTitleContent" },
    { key: "currentProviderLogin", type: "CurrentProviderLoginContent" },
    { key: "wouldYouLikeToJoin", type: "VirtualMSKCareContent" },
    { key: "virtualMSKCare", type: "BenefitsSectionProps" },
    { key: "inPersonCare", type: "InPersonCareContent" },
    { key: "testimonials", type: "TestimonialsContent" },
    { key: "faq", type: "FAQContent" },
    { key: "collToActionSection", type: "CollToActionContent" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`Provider.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    currentProviderLogin,
    wouldYouLikeToJoin,
    virtualMSKCare,
    inPersonCare,
    testimonials,
    faq,
    collToActionSection,
  } = sectionContents as {
    mainSection: ProviderTitleContent;
    currentProviderLogin: CurrentProviderLoginContent;
    wouldYouLikeToJoin: ImageTextSectionContent;
    virtualMSKCare: VirtualMSKCareContent;
    inPersonCare: InPersonCareContent;
    testimonials: TestimonialsContent;
    faq: FAQContent;
    collToActionSection: CollToActionContent;
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };

  return (
    <>
      <MainSection
        imgUrl={`/images/site/Provider/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper=""
        classImage=""
        position="right">
        <h1 className="mb-6 md:mb-9.5">
          <span className="relative mr-2 w-fit text-site-text-32-38 text-site-text-blue-2 md:text-site-text-56-67">
            {mainSection.title.line}
          </span>
          <span className="relative mr-2 inline w-fit text-site-text-32-38 text-site-text-blue-2 before:absolute before:-top-2.5 before:right-[-20px] before:h-[32px] before:w-[28px] before:bg-[url('/images/site/Provider/decor.svg')] before:bg-contain before:bg-no-repeat md:text-site-text-56-67">
            {mainSection.title["lineDecoration-1"]}
          </span>
          <span className="relative inline-flex w-fit text-site-text-32-38 font-normal capitalize text-site-text-blue-2 before:absolute before:bottom-[-15px] before:right-[0px] before:z-[-1] before:h-[16px] before:w-full before:bg-[url('/images/site/Provider/decor_line.svg')] before:bg-cover before:bg-no-repeat md:text-site-text-56-67 md:before:bottom-[-10px]">
            {mainSection.title["lineDecoration-2"]}
          </span>
        </h1>
        <div className="max-w-[500px]">
          <p
            className={classNames("mb-5 font-light text-site-text-blue-2 md:mb-9.5 md:text-site-text-18-27")}>
            {mainSection.description}
          </p>
        </div>
      </MainSection>
      <div className="bg-site-background-blue py-8 md:py-16">
        <div className="container">
          <h2 className="mb:text-site-text-48-57 mb-6 text-center text-site-text-28-33 text-white">
            {currentProviderLogin.title}
          </h2>
          <div className="max-w-[1250px mx-auto] relative">
            <Swiper
              spaceBetween={24}
              slidesPerView={1.1}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
              pagination={{
                el: ".current-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="${className}"></span>`;
                },
              }}
              modules={[Pagination]}>
              {currentProviderLogin.cards.map((card, index) => (
                <SwiperSlide
                  key={`currentProvider-${index}`}
                  className="!flex !h-auto flex-col justify-between rounded-[24px] bg-white px-6 py-4">
                  <div className="mb-4">
                    <Image
                      src={`/images/site/Provider/${card.imageUrl}`}
                      alt={card.title}
                      width={357}
                      height={314}
                      className="mb-4 h-[257px] w-full rounded-[20px] object-cover object-center sm:h-[314px]"
                    />
                    <h3 className="mb-4 text-site-text-18-21 md:text-site-text-20-26">{card.title}</h3>
                    <p className="mb-1 text-site-text-14-21">{card.description}</p>
                  </div>
                  <div>
                    {card.accesForm?.title && (
                      <Button
                        classButton="border-[1px] border-site-background-blue !px-[17px] text-site-background-blue hover:bg-site-background-blue hover:text-white w-full mb-2"
                        size="medium"
                        font="light"
                        rounded="medium"
                        href={card.accesForm?.link || "/"}>
                        {card.accesForm?.title}
                      </Button>
                    )}
                    <Button
                      size="medium"
                      color="orange"
                      font="light"
                      rounded="medium"
                      {...(card.btnLogin?.link ? { href: card.btnLogin?.link } : {})}>
                      <span className="mr-1">{card.btnLogin?.title}</span>
                      <i className="icon-arrowR relative right-0 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1 group-hover:text-[18px]"></i>
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="current-pagination mt-5 flex justify-center lg:hidden"></div>
          </div>
        </div>
      </div>
      <div className="py-8 md:py-16">
        <ImageTextSection
          imageUrl={`/images/site/Provider/${wouldYouLikeToJoin.imageUrl}`}
          position="right"
          classNameWrapper="mb-6 md:mb-16"
          classTitle="max-w-full">
          <h2
            className={classNames(
              "mb-4 max-w-[450px] text-site-text-28-33 font-medium md:mb-10 md:text-site-text-48-57"
            )}>
            {wouldYouLikeToJoin.title}
          </h2>
          <p className={classNames("max-w-[515px] text-site-text-16-24 font-[300] md:text-site-text-18-27")}>
            {wouldYouLikeToJoin.description}
          </p>
        </ImageTextSection>
      </div>
      <div className="bg-site-background-gray pb-0 pt-8 md:pb-16 md:pt-16">
        <ImageTextSection
          imageUrl={`/images/site/Provider/${virtualMSKCare.imageUrl}`}
          position="left"
          classNameWrapper="mb-6 md:mb-16"
          classTitle="max-w-full">
          <h2
            className={classNames(
              "mb-4 max-w-[450px] text-site-text-28-33 font-medium md:mb-10 md:text-site-text-48-57"
            )}>
            {virtualMSKCare.title}
          </h2>
          <p
            className={classNames(
              "mb-5 max-w-[515px] text-site-text-16-24 font-[300] md:text-site-text-18-27"
            )}>
            {virtualMSKCare.description}
          </p>
          <ul className="mb-4 pl-1 md:mb-8">
            {virtualMSKCare.descriptionList.map((item, index) => (
              <li
                key={index}
                className="ml-4 max-w-[515px] list-decimal text-site-text-16-24 font-[300] md:text-site-text-18-27">
                {item}
              </li>
            ))}
          </ul>
        </ImageTextSection>
        <h2 className="mb-4 text-center text-site-text-24-33 md:mb-8 md:text-site-text-28-40">
          {virtualMSKCare.cardsTitle}
        </h2>
        <div className="container">
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            <div className="mx-auto flex max-w-[1257px] flex-col justify-around gap-4">
              {virtualMSKCare.cards.map((card, index) => (
                <div
                  key={index}
                  className="flex max-h-[163px] flex-[1_1_300px] flex-col rounded-[24px] bg-white px-6 py-4">
                  <div className="flex flex-row items-center gap-2 md:flex-col md:items-start">
                    <div className="flex h-16 w-16 items-center justify-center rounded-full bg-site-background-gray p-2.5">
                      <Image
                        src={`/images/site/Provider/${card.imageUrl}`}
                        alt={card.title}
                        width={24}
                        height={24}
                      />
                    </div>
                    <h3 className="text-site-text-18-21 md:mb-1 md:text-site-text-24-33">{card.title}</h3>
                  </div>
                  <p className="mb-1 text-site-text-14-21 font-light">{card.description}</p>
                </div>
              ))}
            </div>
            {/* <div className="relative flex h-[166px] justify-center overflow-hidden sm:h-[250px] md:h-auto md:justify-normal">
            <Image
              src={`/images/site/Provider/${virtualMSKCare.cardsImage}`}
              alt={virtualMSKCare.cardsTitle}
              width={800}
              height={500}
              className="absolute top-0 h-auto w-full max-w-[500px] self-center object-cover md:relative md:max-h-[470px] md:max-w-none md:translate-x-10"
            />
          </div> */}
            <div className="relative h-[166px] overflow-hidden md:h-full md:w-[calc(0%_+_50vw)]">
              <Image
                src={`/images/site/Provider/${virtualMSKCare.cardsImage}`}
                alt={virtualMSKCare.cardsTitle}
                width={800}
                height={500}
                className="absolute top-0 h-auto w-auto self-center object-cover md:h-full md:max-w-none md:translate-x-14 md:py-6"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-8 md:py-16">
        <ImageTextSection
          imageUrl={`/images/site/Provider/${inPersonCare.imageUrl}`}
          position="right"
          classImage="md:max-h-[544px] md:max-w-[567px]"
          classNameWrapper="mb-6 md:mb-16">
          <h2
            className={classNames(
              "mb-6 max-w-[450px] text-site-text-28-33 font-medium md:mb-1 md:text-site-text-48-57"
            )}>
            {inPersonCare.title}
          </h2>
          <p
            className={classNames(
              "mb-5 max-w-[515px] text-site-text-16-24 font-medium md:text-site-text-18-27"
            )}>
            {inPersonCare.subTitle}
          </p>
          <div
            className={classNames(
              "mb-5 max-w-[515px] text-site-text-16-24 font-light md:text-site-text-18-27"
            )}>
            {parse(inPersonCare.description)}
          </div>
          <Button
            size="medium"
            color="orange"
            font="light"
            rounded="medium"
            classButton="w-fit mb-5"
            {...(inPersonCare.button.link ? { href: inPersonCare.button.link } : {})}>
            <span className="mr-1">{inPersonCare.button.title}</span>
            <i className="icon-arrowR relative right-0 flex self-center text-[16px] font-[200] transition-all duration-300 group-hover:-right-1 group-hover:text-[18px]"></i>
          </Button>
          <p className="text-site-text-12-18 font-light">{inPersonCare.notification}</p>
        </ImageTextSection>
      </div>
      <div className="bg-primary-1 py-8 md:py-16">
        <div className="container">
          <h2
            className={classNames(
              "mb-6 text-center text-site-text-28-33 font-normal text-white md:mb-12 md:text-site-text-48-57"
            )}>
            {testimonials.title}
          </h2>
          <TestimonialsSlider testimonials={testimonials} />
        </div>
      </div>
      <div className="bg-site-background-gray py-8 md:py-16">
        <div className="container">
          <h2 className="mb-4 text-center text-site-text-28-33 md:mb-6 md:text-site-text-48-57">
            {faq.title}
          </h2>
          <p className="mb-4 text-center text-site-text-16-24 font-light md:mb-16 md:text-site-text-18-27">
            {faq.description}
          </p>
          <div className="faq">
            {faq.accordions.map((accordion, index) => (
              <Accordion
                key={index}
                accordionTitle={accordion.accordionTitle}
                items={accordion.items}
                classContainer="rounded-[10px] border-[1px] border-gray-5 p-4 mb-[32px]"
                classButton="border-b-[1px] border-gray-5 py-5.5 md:px-6 text-site-text-16-24 md:text-site-text-18-27 bg-site-background-gray"
                classContentWrapper=" bg-site-background-gray"
              />
            ))}
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-white"
        classContentWrapper="max-w-[650px]"
        classTitle="items-center gap-4"
        classDescription="text-center md:text-left"
        classButton="bg-site-btn-orange text-white hover:bg-site-btn-orange-hover w-full md:w-fit"
      />
    </>
  );
};

export default ProviderPageComponent;
