import styled from "styled-components";

export const HolidayGreetingsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }

  .flake-wrapper {
    position: absolute;
    top: -50px;
    animation: falling infinite;
  }

  @keyframes falling {
    0% {
      top: -50px;
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      top: 100%;
      opacity: 0;
    }
    // from {
    //   transform: translateY(0);
    // }
    // to {
    //   transform: translateY(100vh);
    // }
  }
`;
