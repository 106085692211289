// import { AccordionContainer } from "@/components/site/parts/AccordionVacancies/styles";
import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import { MainSection } from "@/components/site/parts/MainSection";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

import { Button } from "../../parts/Button";
import {
  MainSectionProps,
  OurVisionSectionProps,
  WhyWorkWithSectionProps,
  BenefitsSectionProps,
  ComponentProps,
} from "./types";

interface DynamicPageProps {
  pageData: PageActive;
}
const CareersPageComponent = ({ allVacancies, pageData }: ComponentProps & DynamicPageProps) => {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();
  const sections = [
    { key: "mainSection", type: "MainSectionProps" },
    { key: "ourVision", type: "OurVisionSectionProps" },
    { key: "whyWorkWith", type: "WhyWorkWithSectionProps" },
    { key: "benefits", type: "BenefitsSectionProps" },
    { key: "collToActionSection", type: "CollToActionContent" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`Careers.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const { mainSection, ourVision, whyWorkWith, benefits, collToActionSection } = sectionContents as {
    mainSection: MainSectionProps;
    ourVision: OurVisionSectionProps;
    whyWorkWith: WhyWorkWithSectionProps;
    benefits: BenefitsSectionProps;
    collToActionSection: CollToActionContent;
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };

  return (
    <>
      <MainSection
        imgUrl={`/images/site/careers/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper="order-2"
        classImage="order-1 md:max-w-[609px] min-h-[374px] md:min-h-auto max-h-[609px]"
        position="right">
        <h1 className="mb-6 flex flex-col md:mb-9.5">
          <span className="relative inline w-fit break-words text-site-text-32-38 text-site-text-blue-2 before:absolute before:-top-2.5 before:left-[-11px] before:h-[20px] before:w-[28px] before:bg-[url('/images/site/careers/decor-2-desk.svg')] before:bg-contain before:bg-no-repeat md:text-site-text-56-67 md:before:left-[-10px] md:before:h-[30px] md3:before:left-[-20px]">
            {mainSection["title"]["lineDecoration-1"]}
          </span>
          <p className="flex flex-row gap-1">
            <span className="relative inline-flex w-fit break-words text-site-text-32-38 font-normal capitalize text-site-text-blue-2 md:text-site-text-52-62">
              {mainSection["title"]["line"]}
            </span>
            <span className="relative inline-flex w-fit break-words text-site-text-32-38 font-normal capitalize text-site-text-blue-2 before:absolute before:bottom-0 before:right-[0px] before:z-[-1] before:h-[8px] before:w-full before:bg-[url('/images/site/careers/line_decor.svg')] before:bg-cover before:bg-no-repeat md:text-site-text-52-62 md:before:h-[13px]">
              {mainSection["title"]["lineDecoration-2"]}
            </span>
          </p>
        </h1>
        <div className="block max-w-[500px]">
          <p className={classNames("mb-5 text-site-text-18-27 font-light text-site-text-blue-2 md:mb-9.5")}>
            {mainSection["description"]}
          </p>
        </div>
      </MainSection>
      <div className="py-8 md:mb-16">
        <div className="container">
          <div className="flex flex-col gap-6 md:flex-row md:gap-16">
            <Image
              src={`/images/site/careers/${ourVision.imageUrl}`}
              alt="Simple Therapy"
              width={660}
              height={440}
              className="order-2 min-h-[227px] w-auto basis-1/2 overflow-hidden rounded-[40px] object-cover md:order-none md:max-h-[440px] md:min-h-[440px] md:max-w-[660px]"
            />
            <div className="order-1 flex max-w-[520px] basis-1/2 flex-col justify-center self-center md:order-none">
              <h2 className="mb-2 text-site-text-28-33 font-medium md:mb-4 md:text-site-text-48-57">
                {ourVision.title}
              </h2>
              <p className="mb-2 text-site-text-16-24 font-light md:mb-4 md:text-site-text-18-27">
                {ourVision["description-1"]}
              </p>
              <p className="mb-2 text-site-text-16-24 font-light md:mb-4 md:text-site-text-18-27">
                {ourVision["description-2"]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 md:mb-16">
        <div className="container">
          <div className="flex flex-col gap-6 md:flex-row md:gap-16">
            <div className="order-1 flex max-w-[520px] basis-1/2 flex-col justify-center self-center md:order-none">
              <h2 className="mb-2 text-site-text-28-33 font-medium md:mb-4 md:text-site-text-48-57">
                {whyWorkWith.title}
              </h2>
              <p className="mb-2 text-site-text-16-24 font-light md:mb-4 md:text-site-text-18-27">
                {whyWorkWith["description"]}
              </p>
            </div>
            <Image
              src={`/images/site/careers/${whyWorkWith.imageUrl}`}
              alt="Simple Therapy"
              width={660}
              height={440}
              className="order-2 min-h-[227px] w-auto basis-1/2 overflow-hidden rounded-[40px] object-cover md:order-none md:max-h-[440px] md:min-h-[440px] md:max-w-[660px]"
            />
          </div>
        </div>
      </div>
      <div className="bg-site-background-gray py-8 md:py-16">
        <div className="container">
          <div className="mx-auto max-w-[1152px]">
            <h2 className="mb-4 text-center text-site-text-24-28 font-medium md:mb-8 md:text-site-text-32-38">
              {benefits.title}
            </h2>
            <div className="flex flex-wrap gap-6">
              {benefits.cards.map((benefit, index) => (
                <div
                  key={index}
                  className={classNames(
                    "flex flex-[1_1_360px] items-center gap-4 rounded-[24px] bg-white",
                    benefits?.cards.length - 1 <= index
                      ? "flex-row px-6 py-4"
                      : "flex-row px-6 py-4 md:h-[215px] md:flex-col md:justify-center md:py-8"
                  )}>
                  <div className="icon flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-site-background-gray">
                    <Image
                      src={`/images/site/careers/${benefit.icon}`}
                      alt={benefit.title}
                      width={35}
                      height={35}
                    />
                  </div>
                  <h3 className="self-center text-site-text-18-21 capitalize md:text-center md:text-site-text-24-33">
                    {benefit.title}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classNames("py-10 md:py-16")}>
        <div className="container flex flex-col justify-between gap-4 md:flex-row">
          <div className={classNames("")}>
            <h3
              className={classNames(
                "mb-2 flex flex-col gap-4 text-site-text-24-28 md:mb-4 md:text-site-text-32-35"
              )}>
              <Image
                className={classNames("mr-4 h-full max-h-[27px] w-fit")}
                src={`/images/site/careers/${collToActionSection.imageBrand}`}
                width={178}
                height={27}
                alt="Simple Therapy"
              />
              {collToActionSection.title}
            </h3>
            <p
              className={classNames("max-w-[908px] text-site-text-16-24 font-light md:text-site-text-18-27")}>
              {collToActionSection.description}
            </p>
          </div>
          {collToActionSection.buttonText && (
            <Button
              // eventHandler={handleOpenModal}
              href={collToActionSection.buttonLink}
              color="orange"
              size="medium"
              font="light"
              textSize="small"
              rounded="medium"
              classButton="w-full gap-2 self-center md:w-fit">
              <span>{collToActionSection.buttonText}</span>
              <span className="relative right-0 text-[15px] transition-all duration-300 group-hover:-right-1 group-hover:text-[15px]">
                <i className="icon-arrowR flex self-center text-[18px] font-[200]"></i>
              </span>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default CareersPageComponent;
