import { CollToAction } from "@/components/site/parts/CollToAction";
import { CollToActionContent } from "@/components/site/parts/CollToAction/types";
import { MainSection } from "@/components/site/parts/MainSection";
import { PageActive } from "@/types/entities/page";
import { useSiteContext } from "@/utils/siteContext";
import classNames from "classnames";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

import { DiversityEquityMainSectionProps, ItemsArray, InfoTextImageSectionProps } from "./types";

interface DynamicPageProps {
  pageData: PageActive;
}
const DiversityEquityInclusionPageComponent = ({ pageData }: DynamicPageProps) => {
  const { t } = useTranslation("common");
  const { setOpenModal, setClosingModal } = useSiteContext();
  const sections = [
    { key: "mainSection", type: "DiversityEquityMainSectionProps" },
    { key: "infoSTSections", type: "ItemsArray" },
    { key: "communityOutreachSection", type: "InfoTextImageSectionProps" },
    { key: "advancingHealthEquitySection", type: "InfoTextImageSectionProps" },
    { key: "collToActionSection", type: "CollToActionContent" },
  ];
  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`DiversityEquityInclusion.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const {
    mainSection,
    infoSTSections,
    communityOutreachSection,
    advancingHealthEquitySection,
    collToActionSection,
  } = sectionContents as {
    mainSection: DiversityEquityMainSectionProps;
    infoSTSections: ItemsArray;
    communityOutreachSection: InfoTextImageSectionProps;
    advancingHealthEquitySection: InfoTextImageSectionProps;
    collToActionSection: CollToActionContent;
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setClosingModal(false);
  };
  return (
    <>
      <MainSection
        imgUrl={`/images/site/DiversityEquityInclusion/${mainSection.imageUrl}`}
        imgAlt={pageData.imageMainAlt || pageData.translations[0].title || "Simple Therapy"}
        classChildrenWrapper="order-1"
        classImage="order-2 md:max-w-[609px] min-h-[374px] md:min-h-auto max-h-[609px] md:min-h-[609px]"
        position="right">
        <p className="mb-2 text-site-text-20-24 md:mb-4 md:text-site-text-30-36">{mainSection.sudTitle}</p>
        <h1 className="mb-6 md:mb-9.5">
          <span className="relative inline text-site-text-32-38 font-normal capitalize text-site-text-blue-1 md:text-site-text-52-62">
            {parse(mainSection.title["line-1"])}
          </span>
          <span className="relative mr-2 inline-flex text-site-text-32-38 font-normal capitalize text-site-text-blue-1 before:absolute before:bottom-[-15px] before:right-[0px] before:h-[24px] before:w-full before:bg-[url('/images/site/DiversityEquityInclusion/decor_line.svg')] before:bg-contain before:bg-no-repeat md:text-site-text-52-62 md:before:bottom-[-10px]">
            {mainSection.title.lineDecoration}
          </span>
          <span className="relative inline text-site-text-32-38 font-normal capitalize text-site-text-blue-1 md:text-site-text-52-62">
            {parse(mainSection.title["line-2"])}
          </span>
        </h1>
        <div className="max-w-[500px]">
          <p className={classNames("mb-5 text-site-text-18-27 font-light text-site-text-blue-2 md:mb-9.5")}>
            {mainSection["description-1"]}
          </p>
          <p className={classNames("mb-6 text-site-text-18-27 font-light text-site-text-blue-2")}>
            {mainSection["description-2"]}
          </p>
        </div>
      </MainSection>
      <div className="bg-site-background-gray py-8 md:py-16">
        <div className="container">
          <div className="mb-8 grid grid-cols-1 gap-6 md:mb-16 md:grid-cols-2 md:gap-16">
            <Image
              src={`/images/site/DiversityEquityInclusion/${infoSTSections[0].image}`}
              height={440}
              width={660}
              alt={infoSTSections[0].title}
              className="rounded-[40px] object-cover"
            />
            <div className="self-center">
              <h2 className="mb-6 text-site-text-28-33 md:mb-5 md:text-site-text-32-38">
                {infoSTSections[0].title}
              </h2>
              <p className="text-site-text-16-24 font-light md:text-site-text-18-27">
                {infoSTSections[0].description}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-[1fr_1.4fr] md:gap-16">
            <div className="order-2 self-center md:order-none">
              <h2 className="mb-6 text-site-text-28-33 md:mb-5 md:text-site-text-32-38">
                {infoSTSections[1].title}
              </h2>
              <p className="mb-6 text-site-text-16-24 font-light md:mb-8 md:text-site-text-18-27">
                {infoSTSections[1].description}
              </p>
              {infoSTSections[1].actions &&
                infoSTSections[1].actions.map((action, index) => (
                  <div key={index} className="mt-4 flex items-start gap-4">
                    <div className="h-10.2 flex w-10.5 flex-[1_0_auto] items-center justify-center rounded-full bg-white p-2">
                      <Image
                        src={`/images/site/DiversityEquityInclusion/${action.icon}`}
                        height={24}
                        width={24}
                        alt={action.title}
                        className="h-[26px] w-auto"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-site-text-18-27">
                        <span className="mr-1 font-bold">{action.title}</span>
                        <span className="font-light">{action.description}</span>
                      </h3>
                    </div>
                  </div>
                ))}
            </div>
            <Image
              src={`/images/site/DiversityEquityInclusion/${infoSTSections[1].image}`}
              height={440}
              width={660}
              alt={infoSTSections[0].title}
              className="order-1 rounded-[40px] object-cover md:order-none md:min-h-[519px] xl:min-w-[751px]"
            />
          </div>
        </div>
      </div>
      <div className="bg-site-background-blue py-8 md:py-16">
        <div className="container">
          <div className="mb-8 flex flex-col gap-6 md:mb-13.5 md:flex-row md:gap-8 lg:gap-13.5">
            <div className="basis-1/2 self-center text-white">
              <h2 className="mb-6 text-site-text-28-33 md:mb-5 md:text-site-text-32-38">
                {communityOutreachSection.title}
              </h2>
              <p className="mb-6 max-w-[505px] text-site-text-16-24 font-light md:mb-8 md:text-site-text-18-27">
                {communityOutreachSection.description}
              </p>
            </div>
            <Image
              src={`/images/site/DiversityEquityInclusion/${communityOutreachSection.image}`}
              height={440}
              width={660}
              alt={communityOutreachSection.title}
              className="basis-1/2 rounded-[40px] object-cover"
            />
          </div>
          <div className="flex flex-col gap-6 md:flex-row md:gap-8 lg:gap-13.5">
            <Image
              src={`/images/site/DiversityEquityInclusion/${advancingHealthEquitySection.image}`}
              height={440}
              width={660}
              alt={advancingHealthEquitySection.title}
              className="order-2 basis-1/2 rounded-[40px] object-cover md:order-none"
            />
            <div className="order-1 basis-1/2 self-center text-white md:order-none">
              <h2 className="mb-6 text-site-text-28-33 md:mb-5 md:text-site-text-32-38">
                {advancingHealthEquitySection.title}
              </h2>
              <p className="mb-6 max-w-[505px] text-site-text-16-24 font-light md:mb-8 md:text-site-text-18-27">
                {advancingHealthEquitySection.description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <CollToAction
        content={{
          title: collToActionSection.title,
          description: collToActionSection.description,
          buttonText: collToActionSection.buttonText,
          buttonLink: collToActionSection.buttonLink,
          imageBrand: collToActionSection.imageBrand,
        }}
        eventHandler={handleOpenModal}
        classWrapper="bg-site-background-white"
        classTitle="items-start gap-4"
        classContentWrapper="max-w-[650px]"
        classButton="bg-site-btn-orange text-white hover:bg-site-btn-orange-hover self-start md:self-center w-fit"
      />
    </>
  );
};

export default DiversityEquityInclusionPageComponent;
