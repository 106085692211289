import { CardRewiew, TestimonialsContent } from "@/components/site/pages/Provider/types";
import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface TestimonialsSliderProps {
  testimonials: TestimonialsContent;
}

export const TestimonialsSlider = ({ testimonials }: TestimonialsSliderProps) => {
  const [openReviewIndex, setOpenReviewIndex] = useState<number | null>(null);
  const [shouldShowReadMore, setShouldShowReadMore] = useState<boolean[]>([]);
  const textRefs = useRef<(HTMLParagraphElement | null)[]>([]);

  const handleSlideChange = () => {
    setOpenReviewIndex(null);
  };

  const handleReadMoreClick = (index: number) => {
    setOpenReviewIndex(openReviewIndex === index ? null : index);
  };

  useEffect(() => {
    const showReadMoreArray = testimonials?.cards.map((_, index) => {
      const ref = textRefs.current[index];
      if (ref) {
        const fullHeight = ref.scrollHeight - 4;
        const clampedHeight = ref.offsetHeight;
        return fullHeight >= clampedHeight;
      }
      return false;
    });

    setShouldShowReadMore(showReadMoreArray || []);
  }, [testimonials]);

  return (
    <div className="relative mx-auto max-w-[1166px]">
      <Swiper
        spaceBetween={24}
        slidesPerView={1.1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
            pagination: testimonials?.cards.length > 3,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        onSlideChange={handleSlideChange}>
        {testimonials?.cards.map((card, index) => (
          <SwiperSlide
            key={index}
            className="!flex !h-auto flex-col justify-between rounded-[24px] bg-white p-6">
            <div className="mb-4">
              <p className="-mb-4 text-site-text-56-67">“</p>
              <div
                className={classNames("overflow-hidden transition-all duration-300", {
                  "line-clamp-[8]": openReviewIndex !== index,
                  "line-clamp-none": openReviewIndex === index,
                })}>
                <p
                  ref={(el) => {
                    textRefs.current[index] = el;
                  }}
                  className="mb-4 text-site-text-18-21 font-light md:text-site-text-20-26">
                  {card.rewiew}
                </p>
              </div>
              {shouldShowReadMore[index] && (
                <button
                  className="my-2 text-site-text-18-27 font-[100] text-primary-4"
                  onClick={() => handleReadMoreClick(index)}>
                  {openReviewIndex === index ? "Read Less" : "Read More"}
                </button>
              )}
              <p className="mb-1 text-site-text-14-21 font-medium">{card.author.name}</p>
              {card.author.status && (
                <p className="mb-1 text-site-text-14-21 font-light">{card.author.status}</p>
              )}
              {card.author.position && (
                <p className="mb-1 text-site-text-14-21 font-light">{card.author.position}</p>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
