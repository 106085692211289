import { TemplateProps } from "@/components/site/parts/ProviderForms/types";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";

const ProviderForms = ({ children, forms, nameSection }: TemplateProps) => {
  const { t } = useTranslation("common");

  return (
    <>
      {children}
      {forms.length ? (
        <div className="bg-white py-8 md:py-16">
          <div className="container">
            <h2 className="mb-4 text-site-text-18-27 md:text-site-text-28-40">
              {t(`${nameSection}.linksTitle`)}
            </h2>
            <div className="flex flex-col gap-4 text-site-text-16-23 font-bold text-primary-1">
              {forms.map((form, index) => (
                <Link
                  key={index}
                  href={`${form.file && form.file.includes("uploads") ? form.file : `/forms/${form.file}`}`}
                  locale={false}
                  target="_blank"
                  className="group flex w-max flex-row items-center gap-2 transition-all duration-300 hover:text-site-btn-orange"
                  aria-label={form.name && form.name !== "null" ? form.name : ""}
                  title={form.name && form.name !== "null" ? form.name : ""}>
                  <i className="icon-arrowUpRightFromSquare"></i>
                  <span>{form.name && form.name !== "null" ? form.name : ""}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProviderForms;
