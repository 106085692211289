import { Button } from "@/components/site/parts/Button";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import React from "react";

import { MainSection, LoginForms } from "./types";

const MemberAccessLoginPageComponent = () => {
  const { t } = useTranslation("common");
  const comprehensiveCareCardsBtnColors = ["orange", "darkBlue", "darkPurple"];

  const sections = [
    { key: "mainSection", type: "MainSection" },
    { key: "loginForms", type: "LoginForms" },
  ];

  const sectionContents = sections.reduce(
    (acc, { key, type }) => {
      acc[key] = t(`MemberAccessLogin.${key}`, { returnObjects: true }) as any;
      return acc;
    },
    {} as Record<string, any>
  );
  const { mainSection, loginForms } = sectionContents as {
    mainSection: MainSection;
    loginForms: LoginForms[];
  };
  return (
    <div className="container pb-8 md:pb-16">
      <div className="mx-auto max-w-[670px]">
        <h1 className="mb-6 text-center md:mb-10">
          <span className="relative mr-2 inline-flex w-fit text-site-text-48-57 font-normal capitalize text-site-background-blue before:absolute before:-bottom-[6px] before:right-[0px] before:z-0 before:h-[17px] before:w-full before:bg-[url('/images/site/MemberAccessLogin/decor_line.svg')] before:bg-cover before:bg-no-repeat md:text-site-text-56-67 md:before:bottom-[-10px] md:before:h-[20px]">
            {mainSection.title["lineDecoration-1"]}
          </span>
          <span className="relative inline-flex w-fit text-site-text-48-57 font-normal capitalize text-site-background-blue after:absolute after:-right-[20px] after:top-0 after:h-[20px] after:w-[28px] after:bg-[url('/images/site/MemberAccessLogin/decor.svg')] after:bg-contain after:bg-no-repeat md:text-site-text-56-67 md:after:right-[-25px] md:after:h-[32px]">
            {mainSection.title["lineDecoration-2"]}
          </span>
        </h1>
        <p className="mb-8 text-center text-site-text-18-27 font-extralight">{mainSection.description}</p>
      </div>
      <div className="flex flex-wrap justify-center gap-8">
        {loginForms.length &&
          loginForms.map((form, index) => (
            <div
              key={index}
              className="flex w-full max-w-[399px] flex-[1_1_330px] flex-col gap-8 rounded-[30px] p-4 shadow-icon-card md:p-8">
              <Image
                src={`/images/site/MemberAccessLogin/${form.imageUrl}`}
                alt={form.button.title}
                width={335}
                height={263}
                className="w-full object-cover sm:h-[263px]"
              />
              <Image
                src={`/images/site/MemberAccessLogin/${form.icon}`}
                alt={form.button.title}
                width={335}
                height={25}
                className="flex max-h-[25px] w-auto self-start"
              />
              <Button
                size="large"
                color={
                  comprehensiveCareCardsBtnColors[index] as
                    | "darkBlue"
                    | "orange"
                    | "green"
                    | "darkPurple"
                    | "darkOrange"
                    | undefined
                }
                textSize="large"
                font="normal"
                rounded="medium"
                href={form.button.link || "#"}
                target="blank"
                classButton="w-full gap-2">
                <span className="text-site-text-16-23 font-light">{form.button.title}</span>
                <span className="relative right-0 text-[20px] transition-all duration-300 group-hover:-right-1 group-hover:text-[24px]">
                  <i className="icon-arrowR flex self-center text-[18px] font-[200]"></i>
                </span>
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MemberAccessLoginPageComponent;
