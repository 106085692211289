import { useIntersectionObserver } from "@/lib/hooks/useIntersectionObserver";
import classNames from "classnames";
import parse from "html-react-parser";
import Image from "next/image";
import React from "react";

import { ResultsDerivedCardProp } from "./types";

export const ResultsDerivedCard = ({ card, classWrapper, classText, classImage }: ResultsDerivedCardProp) => {
  const elementRef: any = useIntersectionObserver(
    () => {
      const span = elementRef.current?.querySelector("span[data-number]");
      if (span) {
        const targetNumber = parseInt(span.getAttribute("data-number") || "0", 10);
        animateNumber(span, targetNumber);
      }
    },
    { threshold: 0.1 }
  );

  const animateNumber = (element: HTMLElement, target: number) => {
    let start = 0;
    const duration = 3000;
    const step = (timestamp: number) => {
      if (!start) start = timestamp;
      const progress = Math.min((timestamp - start) / duration, 1);
      element.textContent = Math.floor(progress * target).toString();
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  return (
    <div ref={elementRef} className={classNames("rounded-[24px] bg-white px-6 py-4 shadow-14", classWrapper)}>
      <div>
        <Image
          src={`/images/site/SimpleWellbeing/${card.imageUrl}`}
          className={classNames("mb-7 h-[265px] rounded-[20px] object-cover md:h-[314px]", classImage)}
          width={365}
          height={314}
          loading="lazy"
          alt={card.description || "Simple Therapy"}
        />
        <h4 className={classNames("text-center text-site-text-72-80 font-extrabold")}>
          <span data-number={card.percentage}>0</span>
          <span>%</span>
        </h4>
        <p className={classNames("text-center text-site-text-20-26")}>{parse(card.description)}</p>
      </div>
    </div>
  );
};
