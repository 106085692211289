import styled from "styled-components";

export const ContainerDeacription = styled.div`
  a {
    color: #ff790c;
    transition: color 0.3s;
    &:hover {
      color: #ff8a74;
    }
  }
`;

export const ContainerSlider = styled.div`
  // padding: 0 14px;
  width: calc(100vw - (100vw - 100%) / 2);
  // margin-left: auto;
  .swiper {
    position: relative;
    @media (min-width: 768px) {
      &:after {
        content: "";
        position: absolute;
        width: 20%;
        height: 100%;
        background: linear-gradient(89.12deg, rgba(17, 55, 77, 0) 0.91%, #11374d 96.59%);

        top: 0;
        right: 0;
        z-index: 1;
      }
    }
  }
`;

export const SliderPaginationButtons = styled.div`
  position: relative;
  // @media (min-width: 768px) {
  // .btn-container {
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     width: 80%;
  //     height: 100%;
  //     background: linear-gradient(90deg, rgba(217, 217, 217, 0) 24.76%, #e8f4fa 100%);
  //     top: 0;
  //     right: -250px;
  //     @media (min-width: 768px) {
  //       right: -150px;
  //     }
  //     z-index: 1;
  //   }
  //   // }
  // }
  .btn-pagination.active {
    span {
      background: #11374d;
      color: #fff;
    }
  }
`;

export const SliderPaginationDark = styled.div`
  .swiper-pagination-bullet,
  .real-people-pagination {
    border: 1px solid #11374d !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    margin: 0 4px;
  }
  .swiper-pagination-bullet-active {
    background: #11374d !important;
  }
`;

export const SliderContainerRealPeople = styled.div`
  // .swiper-slide {
  //   .slide-btn {
  //     z-index: -10;
  //     opacity: 0;
  //     transition: opacity 0.3s;
  //   }
  // }
  // .swiper-slide.swiper-slide-active {
  //   .slide-btn {
  //     z-index: 10;
  //     opacity: 1;
  //     transition: opacity 0.3s;
  //   }
  // }
`;
