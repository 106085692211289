import classNames from "classnames";
import Image from "next/image";
import React from "react";

import { MainSectionProps } from "./types";

export const MainSection = ({
  children,
  imgUrl,
  position,
  classChildrenWrapper,
  classImage,
  imgAlt,
}: MainSectionProps) => {
  return (
    <div className="">
      <div
        className={classNames(
          "container flex flex-col gap-4 pb-4 sm:pb-12 lg:gap-14",
          position === "left" ? "md:flex-row-reverse" : "md:flex-row"
        )}>
        <div
          className={classNames(
            "basis-1/2 content-center md:order-none md:mb-0",
            classChildrenWrapper,
            imgUrl ? "basis-1/2" : "basis-full"
          )}>
          {children}
        </div>
        {imgUrl ? (
          <div className="flex h-full w-full basis-full justify-end md:basis-1/2">
            <Image
              className={classNames("w-full rounded-[40px] object-cover md:order-none", classImage)}
              src={imgUrl}
              alt={imgAlt || "SimpleTherapy"}
              priority
              sizes="100vw"
              width="609"
              height="695"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
